// Styles
import '@mdi/font/css/materialdesignicons.css'
import "vuetify/styles";
// Vuetify
import { createVuetify } from "vuetify";

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import DateFnsAdapter from '@date-io/date-fns'
import { ja } from 'date-fns/locale'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  date: {
    adapter: new DateFnsAdapter({
      formats: {
        year: 'yyyy年',
        monthAndYear: 'yyyy年M月',
        normalDateWithWeekday: 'M月d日(E)',
      },
      locale: ja,
    }),
  },
  theme: {
    defaultTheme: 'light',
  },
  lang: {
    current: 'ja',
  },
});

export default vuetify;