<template>
  <div class="home pa-2">
    <v-container
      class="pa-0"
      fluid
    >
      <v-row no-gutters>
        <v-col class="pa-0 py-2">
          <h2 id="home-page">
            <v-icon>mdi-account-multiple</v-icon>
            &nbsp;{{ title }}一覧
          </h2>
        </v-col>
        <v-col
          id="guests_head"
          class="pa-0 py-2"
          cols="12"
          md="5"
        >
          <v-row
            no-gutters
            justify="end"
          >
            <v-col class="pr-4">
              <v-text-field
                id="home-search-text"
                v-model="searchText"
                type="search"
                :label="`${title}を検索`"
                density="compact"
                prepend-inner-icon="mdi-magnify"
                clearable
              />
              <v-tooltip
                activator="parent"
                location="top"
              >
                入力した文字を含む{{ title }}を絞り込み表示します
              </v-tooltip>
            </v-col>
            <v-col cols="auto">
              <v-btn
                id="home-create-btn"
                color="primary"
                @click.stop="openGuestDialog('create')"
              >
                <v-icon start>
                  mdi-account-plus
                </v-icon>{{ title }}登録
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  {{ title }}を新規登録します。
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        id="table_upper"
        justify="end"
        class="px-4"
      >
        <v-col cols="6">
          <v-checkbox
            id="home-jiritsu-check"
            v-model="isCare"
            label="自立者のみ表示"
          />
          <v-tooltip
            activator="parent"
            location="top"
          >
            チェックすると判定が自立者のみを表示します。
          </v-tooltip>
        </v-col>
        <v-col
          cols="6"
          class="float-right"
          style="text-align: right;"
        >
          <v-btn
            id="home-refresh"
            width="100%"
            :loading="isLoading"
            :disabled="isLoading"
            @click="updateGuests"
          >
            <v-icon color="gray">
              mdi-reload
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              最新情報に更新
            </v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-sheet elevation="5">
      <v-data-table
        id="home-user-list"
        v-model="guestSelected"
        :headers="headers"
        :items="customData"
        item-value="guestId"
        :loading="isLoading"
        :search="searchText"
        :loading-text="`${title}一覧を取得中...`"
        :no-results-text="`該当する${title}が存在しません。`"
        items-per-page-text="表示行数"
        class="home__datatable"
        show-select
      >
        <template #[`header.customAction`] />
        <template #[`item.customAction`]="{item}">
          <v-row
            id="guest-action"
            style="margin: 0 !important"
          >
            <v-col class="pa-0">
              <v-btn
                id="home-edit-btn"
                variant="text"
                block
                @click.stop="openGuestDialog('update', item)"
              >
                <v-icon color="gray">
                  mdi-pencil
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  {{ title }}の編集
                </v-tooltip>
              </v-btn>
            </v-col>
            <v-col class="pa-0">
              <v-btn
                :id="'home-delete-btn' + item.guestId"
                data-test="home-delete-btn"
                variant="text"
                block
                color="secondary"
                @click.stop="deleteGuest(item)"
              >
                <v-icon color="secondary">
                  mdi-trash-can
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  {{ title }}の削除
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template #[`item.officeName`]="{item}">
          <v-row @click="selectGuest(item)">
            <v-col>
              {{ item.officeName }}
            </v-col>
          </v-row>
        </template>
        <template #[`item.managementNo`]="{item}">
          <v-row @click="selectGuest(item)">
            <v-col>
              {{ item.managementNo }}
            </v-col>
          </v-row>
        </template>
        <template #[`item.name`]="{item}">
          <v-row @click="selectGuest(item)">
            <v-col>
              {{ item.name }}
            </v-col>
          </v-row>
        </template>
        <template #[`item.judge`]="{item}">
          <v-row @click="selectGuest(item)">
            <v-col>
              <div v-if="2 <= item.careLevel && item.careLevel <= 8">
                <v-icon color="black">
                  mdi-account-alert
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  {{ [`要支援1`, `要支援2`, `要介護1`, `要介護2`, `要介護3`, `要介護4`,`要介護5`][item.careLevel - 2] }}
                </v-tooltip>
              </div>
              <div v-else-if="item.judge === 1">
                <v-icon color="black">
                  mdi-inbox-remove
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  未提出
                </v-tooltip>
              </div>
              <div v-else-if="item.judge === 2">
                <v-icon color="red">
                  mdi-comment-remove-outline
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  不備あり
                </v-tooltip>
              </div>
              <template v-else-if="item.judge >= 3">
                <a>
                  <v-icon :color="(item.judge01_20 === 2) ? 'red' : (item.judge01_20 === 1) ? 'gray' : 'orange'">
                    {{ (item.judge01_20 === 2) ? 'mdi-hours-24' : (item.judge01_20 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
                  </v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    ① 全般的な生活機能低下の恐れ {{ (item.judge01_20 === 2) ? '該当' : (item.judge01_20 === 1) ? '非該当' : '未入力あり' }}
                  </v-tooltip>
                </a>
                <a>
                  <v-icon :color="(item.judge06_10 === 2) ? 'red' : (item.judge06_10 === 1) ? 'gray' : 'orange'">
                    {{ (item.judge06_10 === 2) ? 'mdi-yoga' : (item.judge06_10 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
                  </v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    ② 運動機能低下の恐れ {{ (item.judge06_10 === 2) ? '該当' : (item.judge06_10 === 1) ? '非該当' : '未入力あり' }}
                  </v-tooltip>
                </a>
                <a>
                  <v-icon :color="(item.judge11_12 === 2) ? 'red' : (item.judge11_12 === 1) ? 'gray' : 'orange'">
                    {{ (item.judge11_12 === 2) ? 'mdi-noodles' : (item.judge11_12 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
                  </v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    ③ 低栄養の恐れ {{ (item.judge11_12 === 2) ? '該当' : (item.judge11_12 === 1) ? '非該当' : '未入力あり' }}
                  </v-tooltip>
                </a>
                <a>
                  <v-icon :color="(item.judge13_15 === 2) ? 'red' : (item.judge13_15 === 1) ? 'gray' : 'orange'">
                    {{ (item.judge13_15 === 2) ? 'mdi-tooth' : (item.judge13_15 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
                  </v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    ④ 口腔機能低下の恐れ {{ (item.judge13_15 === 2) ? '該当' : (item.judge13_15 === 1) ? '非該当' : '未入力あり' }}
                  </v-tooltip>
                </a>
                <a>
                  <v-icon :color="(item.judge16_17 === 2) ? 'red' : (item.judge16_17 === 1) ? 'gray' : 'orange'">
                    {{ (item.judge16_17 === 2) ? 'mdi-home-account' : (item.judge16_17 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
                  </v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    ⑤ 閉じこもりの恐れ {{ (item.judge16_17 === 2) ? '該当' : (item.judge16_17 === 1) ? '非該当' : '未入力あり' }}
                  </v-tooltip>
                </a>
                <a>
                  <v-icon :color="(item.judge18_20 === 2) ? 'red' : (item.judge18_20 === 1) ? 'gray' : 'orange'">
                    {{ (item.judge18_20 === 2) ? 'mdi-head-question' : (item.judge18_20 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
                  </v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    ⑥ 認知機能低下の恐れ {{ (item.judge18_20 === 2) ? '該当' : (item.judge18_20 === 1) ? '非該当' : '未入力あり' }}
                  </v-tooltip>
                </a>
                <a>
                  <v-icon :color="(item.judge21_25 === 2) ? 'red' : (item.judge21_25 === 1) ? 'gray' : 'orange'">
                    {{ (item.judge21_25 === 2) ? 'mdi-heart-minus' : (item.judge21_25 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
                  </v-icon>
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    ⑦ うつ傾向の可能性あり {{ (item.judge21_25 === 2) ? '該当' : (item.judge21_25 === 1) ? '非該当' : '未入力あり' }}
                  </v-tooltip>
                </a>
              </template>
            </v-col>
          </v-row>
        </template>
        <template #[`item.fb_date`]="{item}">
          <v-row @click="selectGuest(item)">
            <v-col>
              <div v-if="item.fb_date === 2">
                <v-icon color="green">
                  mdi-checkbox-blank-circle-outline
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  実施済み
                </v-tooltip>
              </div>
              <div v-else-if="item.fb_date === 1">
                <v-icon color="red">
                  mdi-close
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  未実施（実施すると⚪︎が表示されます。）
                </v-tooltip>
              </div>
              <div v-else />
            </v-col>
          </v-row>
        </template>
        <template #[`item.motion`]="{item}">
          <v-row @click="selectGuest(item)">
            <v-col>
              <div v-if="item.motion === 2">
                <v-icon color="green">
                  mdi-checkbox-blank-circle-outline
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  実施済み
                </v-tooltip>
              </div>
              <div v-else-if="item.motion === 1">
                <v-icon color="red">
                  mdi-close
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  未実施（実施すると⚪︎が表示されます。）
                </v-tooltip>
              </div>
              <div v-else />
            </v-col>
          </v-row>
        </template>
        <template #[`item.oral`]="{item}">
          <v-row @click="selectGuest(item)">
            <v-col>
              <div v-if="item.oral === 2">
                <v-icon color="green">
                  mdi-checkbox-blank-circle-outline
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  実施済み
                </v-tooltip>
              </div>
              <div v-else-if="item.oral === 1">
                <v-icon color="red">
                  mdi-close
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  未実施（実施すると⚪︎が表示されます。）
                </v-tooltip>
              </div>
              <div v-else />
            </v-col>
          </v-row>
        </template>
        <template #[`item.nutrition`]="{item}">
          <v-row @click="selectGuest(item)">
            <v-col>
              <div v-if="item.nutrition === 2">
                <v-icon color="green">
                  mdi-checkbox-blank-circle-outline
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  実施済み
                </v-tooltip>
              </div>
              <div v-else-if="item.nutrition === 1">
                <v-icon color="red">
                  mdi-close
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  未実施（実施すると⚪︎が表示されます。）
                </v-tooltip>
              </div>
              <div v-else />
            </v-col>
          </v-row>
        </template>
        <template #[`item.updDtm`]="{item}">
          <v-row @click="selectGuest(item)">
            <v-col>
              {{ item.updDtm }}
            </v-col>
          </v-row>
        </template>
        <template #no-data>
          登録された{{ title }}は存在しません。
        </template>
      </v-data-table>
      <v-row
        no-gutters
        justify="end"
      >
        <v-col cols="auto">
          <v-btn
            variant="text"
            :disabled="$_.isEmpty(guestSelected)" 
            @click="pdfCheckLists"
          >
            <v-icon color="gray darken-1">
              mdi-file-pdf-box
            </v-icon>結果
            <v-icon color="gray darken-1">
              mdi-tray-arrow-down
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              選択された利用者の結果のPDFをダウンロードします。
            </v-tooltip>
          </v-btn>
          <v-btn
            variant="text"
            :disabled="$_.isEmpty(guestSelected)" 
            @click="pdfPrediction"
          >
            <v-icon color="gray-darken-1">
              mdi-file-pdf-box
            </v-icon>予測
            <v-icon color="gray-darken-1">
              mdi-tray-arrow-down
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              選択された利用者の予測のPDFをダウンロードします。
            </v-tooltip>
          </v-btn>
          <v-tooltip
            v-if="$_.isEmpty(guestSelected)"
            activator="parent"
            location="top"
          >
            出力する利用者を選択して下さい
          </v-tooltip>
        </v-col>
        <v-col
          v-if="$route.path.substring(0, 9) == '/_guests/'"
          cols="auto"
        >
          <v-btn
            :id="`${$route.name}-delete-all-btn`"
            variant="text"
            :disabled="$_.isEmpty(guestSelected)" 
            @click="deleteGuest(guestSelected)"
          >
            <v-icon color="gray-darken-1">
              mdi-trash-can
            </v-icon>一括削除
          </v-btn>
        </v-col>
      </v-row>
      <template v-if="$route.path.substring(0, 9) == '/_guests/'">
        {{ guestSelected }}<br>
      </template>
    </v-sheet>

    <!-- 入居者様登録or更新ダイアログ -->
    <v-dialog
      id="modal-basic-info"
      v-model="guestDialog.visible"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <h3>{{ `${title}情報の` + (guestDialog.mode == 'create' ? '登録' : '更新') }}</h3>
        </v-card-title>
        <v-card-text>
          <v-form ref="guestDialog">
            <v-container class="pa-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    id="home-dialog-managementNo"
                    v-model="guestDialog.editedItem.managementNo"
                    label="個人番号（必須）"
                    :rules="[rule_managementNo]"
                    counter="15"
                    :disabled="guestDialog.isLoading"
                    maxlength="15"
                    density="compact"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    id="home-dialog-office"
                    v-model="guestDialog.editedItem.officeId"
                    :items="customOffices"
                    item-title="text"
                    item-value="value"
                    label="事業所名"
                    prepend-icon="mdi-home-outline"
                    density="compact"
                    :loading="isOfficesLoading"
                    :disabled="isOfficesLoading || guestDialog.isLoading"
                    :rules="[rule_officeId]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    id="home-dialog-lastname"
                    v-model="guestDialog.editedItem.lastName"
                    label="姓（必須）"
                    :rules="[rule_required, rule_length255]"
                    :disabled="guestDialog.isLoading"
                    density="compact"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    id="home-dialog-firstname"
                    v-model="guestDialog.editedItem.firstName"
                    label="名（必須）"
                    :rules="[rule_required, rule_length255]"
                    :disabled="guestDialog.isLoading"
                    density="compact"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-3">
            <v-row
              no-gutters
              justify="end"
            >
              <v-col
                id="cancel-btn-area"
                cols="12"
                md="4"
                class="pa-1"
              >
                <v-btn
                  id="custom-user-cancel"
                  variant="text"
                  :disabled="guestDialog.isLoading || isOfficesLoading"
                  min-width="150px"
                  block
                  @click="openGuestDialog(null)"
                >
                  キャンセル
                </v-btn>
              </v-col>
              <v-col
                id="submit-btn-area"
                cols="12"
                md="4"
                class="pa-1"
              >
                <v-btn
                  :id="`custom-user-` + guestDialog.mode"
                  color="primary"
                  :loading="guestDialog.isLoading"
                  :disabled="!canAddOrUpdateGuest"
                  min-width="150px"
                  block
                  @click="modifyGuest(guestDialog.mode)"
                >
                  {{ guestDialog.mode == 'create' ? '登録' : '更新' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @namespace app.home
 */
import {mapGetters} from "vuex";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale(config.locale);
import config from "../config";

/**
 * ビューモデル
 * @class
 * @memberof app.home
 */
export default {
  name: "CustomerItem",
  data() {
    return {
      title: "利用者",
      isOfficesLoading: false,
      headers: [
        {
          title: "事業所名",
          value: "officeName",
          class: [],
          sortable: true,
        },
        {
          title: "個人番号",
          value: "managementNo",
          class: [],
          sortable: true,
        },
        {
          title: "利用者名",
          value: "name",
          class: [],
          sortable: true,
        },
        {
          title: "判定",
          value: "judge",
          class: [],
          align: "center",
          sortable: true,
        },
        {
          title: "フィードバック",
          value: "fb_date",
          class: [],
          align: "center",
          sortable: true,
        },
        {
          title: "個別運動",
          value: "motion",
          class: [],
          align: "center",
          sortable: true,
        },
        {
          title: "個別口腔",
          value: "oral",
          class: [],
          align: "center",
          sortable: true,
        },
        {
          title: "個別栄養",
          value: "nutrition",
          class: [],
          align: "center",
          sortable: true,
        },
        {
          title: "更新日",
          value: "updDtm",
          class: [],
          filterable: false,
          sortable: true,
        },
        {
          title: "",
          value: "customAction",
          class: ["text-caption"],
          sortable: false,
        },
      ],
      guestSelected: [],
      guestDialog: {
        mode: "create",
        visible: false,
        isLoading: false,
        menu: false,
        editedItem: {
          guestId: null,
          managementNo: null,
          firstName: null,
          lastName: null,
          officeId: null,
          insDtm: null,
          insUserId: null,
          insUserName: null,
          updDtm: null,
          updUserId: null,
          updUserName: null,
        },
        defaultItem: null,
        max: dayjs().toISOString().slice(0, 10),
        min: dayjs().subtract(200, "year").toISOString().slice(0, 10),
      },
      isCare: false,
      isLoading: false,
      searchText: null,
      pageOptions: null,
    };
  },
  computed: {
    ...mapGetters([
      "activeUser",
      "activeOffices",
      "activeGuests",
      "selectedGuest",
      "storedPageOptions",
    ]),
    /**
     * 入居入居者様一覧の加工
     * @returns {Array} 入居者様一覧
     */
    customData() {
      return _(this.activeGuests || [])
        .filter(x => (this.isCare && x.careLevel == 1) || !this.isCare)
        .map(x => ({
          ..._.pick(x, [
            "guestId",
            "managementNo",
          ]),
          name: `${x.lastName} ${x.firstName}`,
          updDtm: dayjs(x.updDtm).toISOString().substr(0, 10), // dayjs(x.updDtm).format("YYYY.MM.DD"),
          officeId: x.offices.length > 0 ? _.head(x.offices).officeId : null,
          officeName: x.offices.length > 0 ? _.head(x.offices).officeName : "",
          careLevel: x.careLevel, // 自立者：空白 / 自立者以外：入力された要介護状態区分を表示する。
          judge: x.judge,
          judge01_20: x.judge01_20,
          judge06_10: x.judge06_10,
          judge11_12: x.judge11_12,
          judge13_15: x.judge13_15,
          judge16_17: x.judge16_17,
          judge18_20: x.judge18_20,
          judge21_25: x.judge21_25,
          fb_date: x.fb_date,
          motion: x.motion,
          oral: x.oral,
          nutrition: x.nutrition,
        }))
        .value();
    },
    /**
     * 所属事業所情報の加工
     * @returns {Array} 所属事業所情報
     */
    customOffices() {
      return _(this.activeOffices || [])
        .uniqBy(x => x.officeId)
        .map(x => ({
          text: x.officeName,
          value: x.officeId
        }))
        .value();
    },
    /**
     * 入居者様情報更新の判定
     * @returns {Boolean} true:更新可能 / false:更新不可
     */
    canAddOrUpdateGuest() {
      return (
        this.rule_officeId(this.guestDialog.editedItem.officeId) === true &&
        this.rule_required(this.guestDialog.editedItem.managementNo) === true &&
        this.rule_managementNo(this.guestDialog.editedItem.managementNo) === true &&
        this.rule_required(this.guestDialog.editedItem.lastName) === true &&
        this.rule_length255(this.guestDialog.editedItem.lastName) === true &&
        this.rule_required(this.guestDialog.editedItem.firstName) === true &&
        this.rule_length255(this.guestDialog.editedItem.firstName) === true &&
        !this.guestDialog.isLoading &&
        !this.isOfficesLoading
      );
    },
    /**
     * 判定の表示
     * @returns {String} 判定の表示
     */
    dispStatus: function() {
      return function(item) {
        switch (item.judge) {
          case 1:
            return `<div>
  <v-icon color='red'>
    mdi-trading-down
  </v-icon>
  <v-tooltip
    activator="parent"
    location="top"
  >
    未提出
  </v-tooltip>
</div>`;
          case 2:
            return `<div>
  <v-icon color='red'>
    mdi-trading-down
  </v-icon>
  <v-tooltip
    activator="parent"
    location="top"  
  >
    不備あり
  </v-tooltip>
</div>`;
          case 3:
            return `<div>
  <v-icon color='red'>
    mdi-trading-down
  </v-icon>
  <v-tooltip
    activator="parent"
    location="top"
  >
    未入力あり
  </v-tooltip>
</div>`;
        }
        return item.judge;
      };
    },
  },
  watch: {
    /**
     * 入居者様ダイアログのカレンダー表示
     * @param {Boolean} val true:表示 / false:非表示
     */
    "guestDialog.menu"(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    pageOptions: {
      handler(options) {
        if (
          options == null && // 初回表示
          this.storedPageOptions != null
        ) {
          // 保存しているページ状態があれば復帰
          this.pageOptions = this.storedPageOptions;
        }
        this.$store.commit("setPageOptions", options); // 状態を保存
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 入居者様情報の更新
     */
    async updateGuests() {
      this.isLoading = true;
      await this.$store.dispatch("updateGuests");
      this.isLoading = false;
    },
    /**
     * 入居者様の選択
     * @param {Object} item 入居者様情報
     */
     async selectGuest(item) {
      // alert(JSON.stringify(item));
      let pathPrefix = (this.$route.path.substring(0, 9) == '/_guests/') ? '_guests' : 'guests';

      await this.$router
      .push(`/${pathPrefix}/${item.guestId}/checklists`)
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error("ナビゲーションエラー:", error);
      });
    },
    enableCheck(item) {
      if (this.$route.path.substring(0, 9) == '/_guests/') {
        return false;
      }
      if (item.careLevel !== 1) { // 自立以外はNG
        return true;
      }
      if (item.judge < 3) { // 0:空白/1:未提出/2:不備あり/3:提出済み/4:該当なし/5〜 該当あり
        return true;
      }
      return false;
    },
    /**
     * 空欄判定ルール
     * @param {*} value
     */
    rule_required(value) {
      return !!value || "空欄にはできません。";
    },
    /**
     * 255文字以下判定ルール
     * @param {*} value
     */
    rule_length255(value) {
      return (
        (!_.isEmpty(value) && value.length < 256) ||
        "255文字以内にしてください。"
      );
    },
    /**
     * 1023文字以下判定ルール
     * @param {*} value
     */
    rule_length1023(value) {
      return (
        (!_.isEmpty(value) && value.length < 1024) ||
        "1023文字以内にしてください。"
      );
    },
    /**
     * 管理番号ルール
     * @param { * } value 管理番号
     * @returns {Boolean|String} true:正常 / エラーメッセージ
     */
    rule_managementNo(value) {
      return (
        (!_.isEmpty(value) && value.length <= 15) ||
        "15桁以内で入力してください。"
      );
    },
    /**
     * 事業所IDルール
     * @param { * } value 事業所ID
     * @returns {Boolean|String} true:正常 / エラーメッセージ 
     */
    rule_officeId(value) {
      return (
        _.findIndex(this.activeOffices, x => x.officeId === value) >= 0 ||
        "事業所を選択してください。"
      );
    },
    /**
     * 入居者様ダイアログ表示
     * @param {String} mode create:新規登録 / update:更新
     * @param {Object} item 入居者様情報
     */
    openGuestDialog(mode, item) {
      switch (mode) {
        case "create":
          if (!this.guestDialog.visible) {
            // ダイアログをcreateモードで開く
            this.guestDialog.mode = "create";
            this.guestDialog.visible = true;
            if (this.customOffices.length > 0) {
              // 事業所がひとつ以上存在する場合は、ひとつ目をセット
              this.guestDialog.editedItem.officeId = _.head(
                this.customOffices
              ).value;
            }
          }
          break;
        case "update":
          if (!this.guestDialog.visible && item) {
            // ダイアログをupdateモードで開く
            this.guestDialog.mode = "update";
            this.guestDialog.visible = true;

            // 必要な情報をセット
            let guest =
              _.find(this.activeGuests, x => x.guestId === item.guestId) || {};
            _.assign(this.guestDialog.editedItem, _.cloneDeep(guest), {
              officeId: item.officeId
            });
            //this.guestDialog.defaultItem = _.cloneDeep(guest);
          }
          break;
        default:
          if (this.guestDialog.visible) {
            // ダイアログを閉じる
            this.guestDialog.visible = false;

            // フォームをリセット
            this.guestDialog.editedItem.guestId = null;
            //this.guestDialog.defaultItem = {};
            this.$refs.guestDialog.reset();
          }
          break;
      }
    },
    /**
     * 入居者様情報の保存
     * @param {Date} date 保存日
     */
    save(date) {
      this.$refs.menu.save(date);
    },
    /**
     * 入居者様の削除
     */
    async deleteGuest(items) {
      let title = `${this.title}一括削除`;
      let body = `選択した${this.title}を一括削除しますか？`;
      if (!Array.isArray(items)) {
        title = `${this.title}削除`;
        body = `事業所名：${items.officeName} / `
        + `管理番号：${items.managementNo} / `
        + `${items.name}さんの情報を削除しますか？`;
        items = [items.guestId];
      }
      if (await confirm(`${title}
${body}`)) {
        for (let item of items) {
          await this.$store.dispatch("deleteGuest", { guestId: item });
        }
        this.guestSelected = [];
      }
    },

    /**
     * 入居者様の登録/更新
     * @param mode 1:作成 / false:更新
     */
    async modifyGuest(mode) {
      // 入居者様情報の更新
      this.guestDialog.isLoading = true;

      /* 重複チェック */
      let dupGuest = _.find(this.activeGuests || [], (x) => x.managementNo == this.guestDialog.editedItem.managementNo);
      if (!_.isNil(dupGuest) && dupGuest.guestId == this.guestDialog.editedItem.guestId) {
        dupGuest = null;
      }
      if (dupGuest) {
        await this.$dialog.alert(`既に個人番号(${this.guestDialog.editedItem.managementNo})が登録されています。 個人番号は重複できません。`);
        this.guestDialog.isLoading = false;
        return;
      }
      if (mode == 'create') {
        let guest = _.cloneDeep(_.omit(this.guestDialog.editedItem, ["guestId"]));
        await this.$store.dispatch("createGuest", guest);
      } else {
        let guest = _.cloneDeep(this.guestDialog.editedItem);
        await this.$store.dispatch("updateGuest", guest);
      }
      this.guestDialog.isLoading = false;
      this.openGuestDialog(null);
    },
    /**
     * チェックリストPDF出力
     */
    async pdfCheckLists() {
      let guests = [];
      for (let _guest of this.guestSelected) {
        guests.push(_guest.guestId);
      }
      let data = await this.$store.dispatch("pdfCheckList", {
        guests
      });
      // base64 decode
      let base64 = data["DATA"];
      let bin = atob(base64.replace(/^.*,/, ''));
      let buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      const blob = new Blob([buffer.buffer], {type: 'application/pdf'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      //link.target = '_blank';
      link.download = 'CheckList_' + dayjs().format('YYYYMMDD') + '.pdf';
      link.click();
    },
    /**
     * 予測PDF出力
     */
    async pdfPrediction() {
      let guests = [];
      for (let _guest of this.guestSelected) {
        guests.push(_guest.guestId);
      }
      let fiscalYear = this.fiscalYear;
      let data = await this.$store.dispatch("pdfPrediction", { guests, fiscalYear });
      // base64 decode
      let base64 = data["DATA"];
      let bin = atob(base64.replace(/^.*,/, ''));
      let buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      const blob = new Blob([buffer.buffer], {type: 'application/pdf'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      //link.target = '_blank';
      link.download = 'Prediction_' + dayjs().format('YYYYMMDD') + '.pdf';
      link.click();
    },
  },
};
</script>

<style scoped lang="scss">
#cancel-btn-area,
#submit-btn-area {
  button {
    padding: 0 50%;
  }
}
:deep(#submit-btn-area ){
  background-color: #00aee8 !important;
  button.text-primary span.v-btn__content{
    color:#fff!important;
  }
}
.home {
  &__datatable {
    background: transparent !important;
  }
  #home-create-btn i.v-icon--size-default,
  #home-life-btn i.v-icon--size-default{
    font-size: 18px !important; 
  }
  #home-life-btn {
    background-color: #00aee8 !important;
  }
  #guest-action{
    button {
      padding: 0;
    }
  }
  h2#home-page i.v-icon--size-default {
    font-size: 24px !important;
  }
}
@media (min-width: 960px) {
  .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
:deep(#guests_head ){
  .v-row + .v-row {
    margin-top: 0 !important;
  }
  div.v-row > div.v-col {
    padding-top: 0 !important;
  }
 .v-input__details {
    padding: 0 !important;
    min-height: 0;
  }
}
:deep(#table_upper ){
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
  div.v-col {
    padding-bottom: 0 !important;
    padding-left: 4px !important;
    margin-bottom: 26px;
    padding-top: 0;
   i.mdi-checkbox-marked{
      color: #00aee8 !important;
    }
  }
  .right {
    text-align: right;
  }
  button#home-refresh {
    margin-top: 16px;
    min-width: auto !important;
    max-width: 3rem;
  }
}
:deep(#home-user-list table ){
  td,
  th {
    padding: 0 10px !important;
    font-size: 0.875rem !important;
  }
  th {
    background-color: #eee;
    font-weight: bold;
    color: #000;
    span {
      font-size: 0.8rem !important;
    }
  }
}
:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td ){
  div > div > button {
    padding: 0;
  }
}
</style>