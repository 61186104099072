<template>
  <div
    v-if="selectedGuest"
    class="careplan pa-2"
  >
    <v-container
      class="pa-0"
      fluid
    >
      <v-row no-gutters>
        <v-col
          id="care-plan-guest-name"
          class="py-2"
          justify="center"
        >
          <v-skeleton-loader
            v-if="isGuestsLoading || !selectedGuest"
            type="heading"
          />
          <h2
            v-else
            id="guest-name"
            style="display:block; line-height: 1.2; float:left;"
          >
            <b
              v-if="!isHistoryLoading"
              style="vertical-align: middle; padding-right:10px"
            >
              {{ selectedGuest.lastName }} {{ selectedGuest.firstName }}</b><span
              class="text-subtitle-1 mr-1"
            >様の履歴</span>
          </h2>
        </v-col>
        <v-col align="end">
          <v-btn
            id="home-refresh"
            width="3rem"
            :loading="isHistoryLoading"
            :disabled="isHistoryLoading"
            @click="refresh"
          >
            <v-icon color="gray">
              mdi-reload
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              最新情報に更新
            </v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-sheet elevation="5">
      <v-container
        class="pa-0"
        fluid
      >
        <v-row
          no-gutters
          class="careplan__container"
        >
          <v-col
            :class="{'hide--overflow': display.xs.value || display.sm.value}"
          >
            <v-row
              class="hide--print"
              no-gutters
            >
              <v-col
                id="mainTabAreaOuter"
                align-self="start"
              >
                <v-tabs
                  id="mainTabArea"
                  v-model="tabIdx"
                  color="primary"
                  bg-color="transparent"
                >
                  <v-tab
                    id="tab-1"
                    key="tab-1"
                    :disabled="isCheckListsLoading"
                    @click.stop="tabSelect(0)"
                  >
                    <span>チェック</span>
                    <span>リスト</span>
                  </v-tab>
                  <v-tab
                    id="tab-2"
                    key="tab-2"
                    :disabled="isCheckListsLoading"
                    @click.stop="tabSelect(1)"
                  >
                    予測
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>

            <!-- タブ:チェック -->
            <v-row
              v-if="tabIdx == 0 && !isHistoryLoading"
              no-gutters
            >
              <v-col
                cols="12"
                class="pa-6"
              >
                <v-expansion-panels
                  v-model="customPanel_1"
                  multiple
                >
                  <v-expansion-panel key="check-panel-0">
                    <v-expansion-panel-title>
                      <div class="text-h6">
                        判定
                      </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <table
                        class="conditionlist__table"
                        :class="{'conditionlist__table--small': display.xs.value}"
                      >
                        <tbody>
                          <tr>
                            <td width="40%" />
                            <td width="20%">
                              {{ fiscalYear - 2 }}年
                            </td>
                            <td width="20%">
                              {{ fiscalYear - 1 }}年
                            </td>
                            <td width="20%">
                              {{ fiscalYear }}年
                            </td>
                          </tr>
                          <tr>
                            <th>判定</th>
                            <td v-html="histJudgeDisp('judge_i_iv', 2, false)" />
                            <td v-html="histJudgeDisp('judge_i_iv', 1, false)" />
                            <td v-html="histJudgeDisp('judge_i_iv', 0, false)" />
                          </tr>
                          <tr>
                            <th>① 全般的な生活機能低下の恐れ</th>
                            <td v-html="histJudgeDisp('judge01_20', 2, false)" />
                            <td v-html="histJudgeDisp('judge01_20', 1, false)" />
                            <td v-html="histJudgeDisp('judge01_20', 0, false)" />
                          </tr>
                          <tr>
                            <th>② 運動機能低下の恐れ</th>
                            <td v-html="histJudgeDisp('judge06_10', 2, false)" />
                            <td v-html="histJudgeDisp('judge06_10', 1, false)" />
                            <td v-html="histJudgeDisp('judge06_10', 0, false)" />
                          </tr>
                          <tr>
                            <th>③ 低栄養の恐れ</th>
                            <td v-html="histJudgeDisp('judge11_12', 2, false)" />
                            <td v-html="histJudgeDisp('judge11_12', 1, false)" />
                            <td v-html="histJudgeDisp('judge11_12', 0, false)" />
                          </tr>
                          <tr>
                            <th>④ 口腔機能低下の恐れ</th>
                            <td v-html="histJudgeDisp('judge13_15', 2, false)" />
                            <td v-html="histJudgeDisp('judge13_15', 1, false)" />
                            <td v-html="histJudgeDisp('judge13_15', 0, false)" />
                          </tr>
                          <tr>
                            <th>⑤ 閉じこもりの恐れ</th>
                            <td v-html="histJudgeDisp('judge16_17', 2, false)" />
                            <td v-html="histJudgeDisp('judge16_17', 1, false)" />
                            <td v-html="histJudgeDisp('judge16_17', 0, false)" />
                          </tr>
                          <tr>
                            <th>⑥ 認知機能低下の恐れ</th>
                            <td v-html="histJudgeDisp('judge18_20', 2, false)" />
                            <td v-html="histJudgeDisp('judge18_20', 1, false)" />
                            <td v-html="histJudgeDisp('judge18_20', 0, false)" />
                          </tr>
                          <tr>
                            <th>⑦ うつ傾向の可能性あり</th>
                            <td v-html="histJudgeDisp('judge21_25', 2, false)" />
                            <td v-html="histJudgeDisp('judge21_25', 1, false)" />
                            <td v-html="histJudgeDisp('judge21_25', 0, false)" />
                          </tr>
                        </tbody>
                      </table>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-row
                  v-for="(group, g) in customData"
                  :key="`check-group-${g}`"
                >
                  <v-col cols="12">
                    <h3 class="conditionlist__groupname">
                      {{ group.name }}
                    </h3>
                    <table
                      class="conditionlist__table"
                      :class="{'conditionlist__table--small': display.xs.value}"
                    >
                      <tbody>
                        <tr>
                          <td width="40%" />
                          <td width="20%">
                            {{ fiscalYear - 2 }}年
                          </td>
                          <td width="20%">
                            {{ fiscalYear - 1 }}年
                          </td>
                          <td width="20%">
                            {{ fiscalYear }}年
                          </td>
                        </tr>
                        <template v-for="(item, i) in group.items">
                          <tr
                            v-if="item.type !== 'etc'"
                            v-show="item.key !== 'judge_i_iv'"
                            :key="i"
                            :class="{
                              'points_text': (item.type === 'judge' && $_.includes(item.key, 'judge')),
                              'alert': (item.type === 'judge' && ($_.includes(histJudge(item, 0), '該当あり') || $_.includes(histJudge(item, 1), '該当あり') || $_.includes(histJudge(item, 2), '該当あり'))),
                              'check': $_.includes(item.key, 'check'),
                              'judge': $_.includes(item.key, 'judge'),
                              [item.key]: !$_.isNil(item.key) && (!$_.includes(item.key, 'check') && !$_.includes(item.key, 'judge')),
                              'other': $_.isNil(item.key)
                            }"
                          >
                            <th v-html="item.title" />
                            <td :id="$_.kebabCase(item.key)">
                              <template v-if="item.type === 'bmi'">
                                <span v-html="histCalcBMI(2)" />
                              </template>
                              <template v-else-if="item.type === 'chip-group' && !item.options.multiple && !$_.isNil(item.value[2])">
                                {{ $_.get($_.find(item.options.items, x => x.value === item.value[2]), 'text') }}
                                <template
                                  v-if="!$_.isUndefined(item.etc) && !$_.isNil(item1 = $_.find(checklists['check'], (x) => x.key === item.etc.key))
                                    && item.value[2] == item.etc.id"
                                >
                                  ：<span v-html="item1.title" />
                                  {{ item1.value[2] }} {{ item1.options.suffix }}
                                </template>
                              </template>
                              <template v-else-if="item.type === 'chip-group' && item.options.multiple && !$_.isNil(item.value[2]) && !$_.isEmpty(item.value[2])">
                                {{ $_(item.options.items).filter(x => $_.indexOf(item.value[2], x.value) >= 0).map(x => x.text).value().join('，') }}
                                <template
                                  v-if="!$_.isUndefined(item.etc) && !$_.isNil(item1 = $_.find(checklists['check'], (x) => x.key === item.etc.key))
                                    && item.value[2].indexOf(item.etc.id) != -1"
                                >
                                  ：<span v-html="item1.title" />
                                  {{ item1.value[2] }} {{ item1.options.suffix }}
                                </template>
                              </template>
                              <template v-else-if="item.type === 'ew-chip-group' && !$_.isNil(item.value[2]) && !$_.isEmpty(item.value[2])">
                                {{ $_(item.options.items).filter(x => $_.indexOf(item.value[2], x.value) >= 0).map(x => x.text).value().join('，') }}
                              </template>
                              <template v-else-if="item.type === 'text-field' && !$_.isNil(item.value[2])">
                                {{ item.value[2] }} {{ item.options.suffix }}
                              </template>
                              <template v-else-if="item.type === 'date-date' && !$_.isNil(item.value[2]) && !$_.isEmpty(item.value[2])">
                                {{ item.value[2] }}
                              </template>
                              <template v-else-if="item.type === 'judge' && !$_.isNil(item.value[2]) && $_.isEmpty(item.value[2])">
                                <span v-html="histJudge(item, 2)" />
                              </template>
                              <template v-else-if="item.type === 'title'" />
                              <template v-else>
                                -
                              </template>
                            </td>
                            <td :id="$_.kebabCase(item.key)">
                              <template v-if="item.type === 'bmi'">
                                <span v-html="histCalcBMI(1)" />
                              </template>
                              <template v-else-if="item.type === 'chip-group' && !item.options.multiple && !$_.isNil(item.value[1])">
                                {{ $_.get($_.find(item.options.items, x => x.value === item.value[1]), 'text') }}
                                <template
                                  v-if="!$_.isUndefined(item.etc) && !$_.isNil(item1 = $_.find(checklists['check'], (x) => x.key === item.etc.key))
                                    && item.value[1] == item.etc.id"
                                >
                                  ：<span v-html="item1.title" />
                                  {{ item1.value[1] }} {{ item1.options.suffix }}
                                </template>
                              </template>
                              <template
                                v-else-if="item.type === 'chip-group' && item.options.multiple && !$_.isNil(item.value[1]) && !$_.isEmpty(item.value[1])"
                              >
                                {{ $_(item.options.items).filter(x => $_.indexOf(item.value[1], x.value) >= 0).map(x => x.text).value().join('，') }}
                                <template
                                  v-if="!$_.isUndefined(item.etc) && !$_.isNil(item1 = $_.find(checklists['check'], (x) => x.key === item.etc.key))
                                    && item.value[1].indexOf(item.etc.id) != -1"
                                >
                                  ：<span v-html="item1.title" />
                                  {{ item1.value[1] }} {{ item1.options.suffix }}
                                </template>
                              </template>
                              <template v-else-if="item.type === 'ew-chip-group' && !$_.isNil(item.value[1]) && !$_.isEmpty(item.value[1])">
                                {{ $_(item.options.items).filter(x => $_.indexOf(item.value[1], x.value) >= 0).map(x => x.text).value().join('，') }}
                              </template>
                              <template v-else-if="item.type === 'text-field' && !$_.isNil(item.value[1])">
                                {{ item.value[1] }} {{ item.options.suffix }}
                              </template>
                              <template v-else-if="item.type === 'date-date' && !$_.isNil(item.value[1]) && !$_.isEmpty(item.value[1])">
                                {{ item.value[1] }}
                              </template>
                              <template v-else-if="item.type === 'judge' && !$_.isNil(item.value[1]) && $_.isEmpty(item.value[1])">
                                <span v-html="histJudge(item, 1)" />
                              </template>
                              <template v-else-if="item.type === 'title'" />
                              <template v-else>
                                -
                              </template>
                            </td>
                            <td :id="$_.kebabCase(item.key)">
                              <template v-if="item.type === 'bmi'">
                                <span v-html="histCalcBMI(0)" />
                              </template>
                              <template v-else-if="item.type === 'chip-group' && !item.options.multiple && !$_.isNil(item.value[0])">
                                {{ $_.get($_.find(item.options.items, x => x.value === item.value[0]), 'text') }}
                                <template
                                  v-if="!$_.isUndefined(item.etc) && !$_.isNil(item1 = $_.find(checklists['check'], (x) => x.key === item.etc.key))
                                    && item.value[0] == item.etc.id"
                                >
                                  ：<span v-html="item1.title" />
                                  {{ item1.value[0] }} {{ item1.options.suffix }}
                                </template>
                              </template>
                              <template v-else-if="item.type === 'chip-group' && item.options.multiple && !$_.isNil(item.value[0]) && !$_.isEmpty(item.value[0])">
                                {{ $_(item.options.items).filter(x => $_.indexOf(item.value[0], x.value) >= 0).map(x => x.text).value().join('，') }}
                                <template
                                  v-if="!$_.isUndefined(item.etc) && !$_.isNil(item1 = $_.find(checklists['check'], (x) => x.key === item.etc.key))
                                    && item.value[0].indexOf(item.etc.id) != -1"
                                >
                                  ：<span v-html="item1.title" />
                                  {{ item1.value[0] }} {{ item1.options.suffix }}
                                </template>
                              </template>
                              <template v-else-if="item.type === 'ew-chip-group' && !$_.isNil(item.value[0]) && !$_.isEmpty(item.value[0])">
                                {{ $_(item.options.items).filter(x => $_.indexOf(item.value[0], x.value) >= 0).map(x => x.text).value().join('，') }}
                              </template>
                              <template v-else-if="item.type === 'text-field' && !$_.isNil(item.value[0])">
                                {{ item.value[0] }} {{ item.options.suffix }}
                              </template>
                              <template v-else-if="item.type === 'date-date' && !$_.isNil(item.value[0]) && !$_.isEmpty(item.value[0])">
                                {{ item.value[0] }}
                              </template>
                              <template v-else-if="item.type === 'judge' && !$_.isNil(item.value[0]) && $_.isEmpty(item.value[0])">
                                <span v-html="histJudge(item, 0)" />
                              </template>
                              <template v-else-if="item.type === 'title'" />
                              <template v-else>
                                -
                              </template>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- タブ:予測 -->
            <v-row
              v-else-if="tabIdx == 1 && !isCheckListsLoading"
              no-gutters
            >
              <v-col
                cols="12"
                class="pa-6"
              >
                <v-expansion-panels
                  v-model="customPanel_2"
                  multiple
                >
                  <v-expansion-panel>
                    <v-expansion-panel-title>
                      <div class="text-h6">
                        予測
                      </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="pa-0"
                        >
                          <ew-line-chart
                            :chart-data="predictData.chartData"
                            :custom-options="predictData.options"
                          />
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- AIモデル実行時に表示するダイアログ -->
    <v-dialog
      :model-value="isHistoryLoading"
      persistent
      width="300px"
    >
      <v-card
        color="info"
        dark
      >
        <v-card-text>
          <span>履歴情報を読み込み中・・・</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @namespace app.hist
 */
import {mapGetters} from "vuex";
import _ from "lodash";
import dayjs from "dayjs";
// dayjs設定
import "dayjs/locale/ja";
dayjs.locale(config.locale);
// 設定ファイル
import config from "../config";
// チェックリスト
import checklists from "../data/checklists";
import EwLineChart from "../components/EwLineChart.vue";
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import { useDisplay } from 'vuetify';

/**
 * ビューモデル
 * @class
 * @memberof app.hist
 */
export default {
  name: "HistItem",
  components: {
    EwLineChart,
  },
  /**
   * 遷移前の処理
   * @param {*} to 遷移先
   * @param {*} from 遷移元
   * @param {*} next 遷移許可
   */
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.token) {
        return await vm.checkHistoryRoute(
          {
            ..._.pick(to.params, ["guestId"]),
            hash: to.hash,
          },
          true
        );
      }
    });
  },
  /**
   * ページ内遷移の処理
   * @param {*} to 遷移先
   * @param {*} from 遷移元
   * @param {*} next 遷移許可
   */
  async beforeRouteUpdate(to, from, next) {
    // ユーザ確認
    if (_.isNil(this.activeUser) || _.isNil(this.activeUser.userId)) {
      // ログインダイアログ
      await this.$store.dispatch("openLoginDialog");
      next();
    } else {
      // 検証
      next(
        await this.checkHistoryRoute({
          ..._.pick(to.params, ["guestId"]),
          hash: to.hash,
        })
      );
    }
  },
  setup() {
    const display = useDisplay();
    return {
      display
    };
  },
  data() {
    return {
      isGuestsLoading: false,
      isCheckListsLoading: false,
      isHistoryLoading: false,
      defaultTab: 0,
      typeNames: [
        "check",
        "predict",
      ],
      customPanel_1: [0, 1, 2],
      customPanel_2: [0, 1],
      tabIdx: 0,
      checklists,
      reg_checklists: [],
      reg_results: [],
      result_datas : [],
      predict: null,
      isHistoryClosed: true,
      selectedHist: null,
    };
  },
  computed: {
    ...mapGetters([
      "token",
      "activeUser",
      "activeGuests",
      "selectedGuest",
      "activeCheckLists",
      "selectedCheckList",
      "activeHists",
    ]),
    /**
     * 年度計算
     */
    fiscalYear() {
      return Number(dayjs().format('YYYY'));
    },
    /**
     * 予測データ
     */
    predictData() {
      let first = _.cloneDeep(this.result_datas);
      first.pop(); // 最初のデータを削除して、青実線で描画する部分を作成する
      let last = [ // 赤点線で描画する部分を作成する
        null, null, null, // 青実線部分は描画しない。
        this.result_datas[this.result_datas.length - 2], // 今年のデータ
        this.result_datas[this.result_datas.length - 1], // 予測データ
      ];
      /*
      let first = this.result_datas;
      let last = this.result_datas; 
      */
      return {
        chartData: {
          labels: [
            `${this.fiscalYear - 3}年`,
            `${this.fiscalYear - 2}年`,
            `${this.fiscalYear - 1}年`,
            `${this.fiscalYear}年`,
            `${this.fiscalYear + 1}年`,
          ],
          datasets: [
            {
              label: "状態",
              borderColor: "blue",
              backgroundColor: "blue",
              fill: false,
              data: first, // 青実線で描画する部分
            },
            {
              label: "予測",
              borderColor: "red",
              backgroundColor: "red",
              borderDash: [5, 5],
              fill: false,
              data: last, // 赤点線で描画する部分
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            y: {
              ticks: {
                callback: (value) => {
                  let labels = [`要介護状態`, ``, `フレイル`, ``, `プレフレイル`, ``, `自立`];
                  return labels[value];
                },
              },
              min: 0,
              max: 6,
              stepSize: 2,
            },
          },
        },
      };
    },
    /**
     * 調査票・チェック判定
     * @param objerct item 対象項目
     */
    histJudge: function() {
      return function(item, idx) {
        let value = [];
        if (item.key == 'check_input') { // 入力状況(チェックリスト)
          value = _.get(_.find(this.customCheckList, (x) => x.key === item.key), 'value') || [0, 0, 0];
          if (value[idx] == 2) {
            return "<font color='green'>入力完了</font>";
          }
          return "<font color='red'>未入力あり</font>";
        }
        return this.histJudgeDisp(item.key, idx);
      }
    },
    /**
     * BMI値計算
     * @param object check チェックリストデータ
     * @param int mode 0:表示(default), 2:編集
     */
    histCalcBMI: function() {
      return function(idx) {
        let heights = _.get(_.find(this.customCheckList, (x) => x.key === 'height'), "value");
        let weights = _.get(_.find(this.customCheckList, (x) => x.key === 'weight'), "value");
        if (Array.isArray(heights) && Array.isArray(weights)) {
          let height = heights[idx];
          if (_.isEmpty(height) || height <= 0) {
            return '';
          }
          height /= 100;
          let weight = weights[idx];
          if (_.isEmpty(weight)) {
            return '';
          }
          let bmi = weight / height / height;
          if (!_.isNaN(bmi)) {
            return bmi.toFixed(1) + "[kg/㎡] = " + weight.toString() + "[kg] ÷ " + height.toString() + "<sup>2</sup>[㎡]";
          }
        }
        return '';
      };
    },
    /**
     * チェック判定表示
     * @param objerct key キー
     * @param objerct check チェックオブジェクト
     */
    histJudgeDisp: function() {
      return function(key, idx, mode = true) {
        // 判定結果を表示する場合
        if (key === 'judge_i_iv') {
          let keys = ["judge01_20", "judge06_10", "judge11_12", "judge13_15", "judge16_17", "judge18_20", "judge21_25"];
          let exist2 = 0; // 該当なし
          let exist1 = 0;
          let exist0 = 0;
          for (let key of keys) {
            let val = _.get(_.find(this.customCheckList, (x) => x.key === key), 'value');
            // alert(JSON.stringify(val));
            if (!_.isNil(val)) {
              if (2 === val[idx]) {
                exist2++; // 該当ありかどうか
              } else if (1 === val[idx]) {
                exist1++;
              } else if (0 === val[idx]) {
                exist0++;
              }
            }
          }
          if (exist0) {
            return "未入力あり";
          } else if (exist2) {
            return "<mark class='alert'>該当あり</mark>";
          } else if (exist1 && exist0 == 0 && exist2 == 0) {
            return "<mark class='normal'>該当なし</mark>";
          }
          return "-";
        }
        let item = _.find(this.customCheckList, (x) => x.key === key);
        if (key == 'check012') { // 栄養状態
          if (item.value[idx] === 0) {
            return "<mark class='normal'>BMIが 18.5以上です</mark>";
          } else if (item.value[idx] === 1) {
            return "<mark class='alert'>BMIが 18.5未満です</mark>";
          }
          return "未入力あり";
        }
        // 判定
        let judges = {
          "judge01_20" : { // No.35 1〜20の点数
            keys: ["check001", "check002", "check003", "check004", "check005", "check006", "check007", "check008", "check009", "check010",
              "check011", "check012", "check013", "check014", "check015", "check016", "check017", "check018", "check019", "check020",],
            boundary: 10,
            suffix: ":10項目以上に該当あり）"
          },
          "judge06_10" : { // No.36 運動不足予防点数
            keys: ["check006", "check007", "check008", "check009", "check010",],
            boundary: 3,
            suffix: ":3項目以上に該当あり）",
          },
          "judge11_12" : { // No.37 栄養改善点数
            keys: ["check011", "check012",],
            boundary: 2,
            suffix: ":2項目に該当あり）",
          },
          "judge13_15" : { // No.38 口腔ケア予防点数
            keys: ["check013", "check014", "check015",],
            boundary: 2,
            suffix: ":2項目以上に該当あり）",
          },
          "judge16_17" : { // 閉じこもりの恐れ
            keys: ["check016", "check017",],
            boundary: 1,
            suffix: ":16に該当あり）",
          },
          "judge18_20" : { // 認知機能低下の恐れ
            keys: ["check018", "check019", "check020",],
            boundary: 1,
            suffix: ":1項目以上に該当あり）",
          },
          "judge21_25" : { // うつ傾向の可能性あり
            keys: ["check021", "check022", "check023", "check024", "check025",],
            boundary: 2,
            suffix: ":2項目以上に該当あり）",
          }
        };
        if (!_.isNil(judges[item.key]) && mode) {
          let judge = judges[item.key];
          let total = 0;
          if (item.key == "judge16_17") {
            let val = _.get(_.find(this.customCheckList, (x) => x.key === 'check016'), "value");
            total = !_.isNil(val[idx]) ? val[idx] : -1;
          } else {
            for (let key of judge.keys) {
              let value = _.get(_.find(this.customCheckList, (x) => x.key === key), "value");
              if (!_.isNil(value[idx])) {
                total += value[idx];
              } else {
                total = -1; // 未入力あり。
                break;
              }
            }
          }
          let result = "未入力あり";
          if (total === -1) {
            // item.value = 0; // 未入力あり
          } else {
            // item.value = (total >= judge.boundary) ? 2 : 1; // 1:該当なし
            result = _.toString(total);
            try {
              result += " " + item.options.suffix;
            } catch (err) {
              //
            }
            if (item.value[idx] == 2) { // 該当あり
              return "該当あり（" + result + judge.suffix;
            }
            return "該当なし（" + result + "）"
          }
          return result;
        }
        return this.JudgeDisp2(item.value[idx]);
      }
    },
    /**
     * グループ化
     * @param {string} index インデックス
     * @returns {string} 値
     */
    customData: function() {
      let result = [];
      let group = null;
      let i = 0;
      _.forEach(this.customCheckList, (item) => {
        // 新規グループのチェック
        if (item.group !== group) {
          // グループ登録
          result.push({
            name: item.group,
            items: [],
            id: "inputGroup_" + i
          });
          i++
          group = item.group;
        }
        // アイテム登録（ポインタコピー）
        _.last(result).items.push(item);
      });
      return result;
    },
    /**
     * BMI値計算
     * @param object check チェックリストデータ
     * @param int mode 0:表示(default), 2:編集
     */
    calcBMI: function() {
      return function(check, mode = 0) {
        let height = _.get(_.find(check, (x) => x.key === 'height'), "value");
        let check012 = _.find(check, (x) => x.key === 'check012');
        if (_.isEmpty(height) || height <= 0) {
          check012.value = null;
          return '';
        }
        height /= 100;
        let weight = _.get(_.find(check, (x) => x.key === 'weight'), "value");
        if (_.isEmpty(weight)) {
          check012.value = null;
          return '';
        }
        let bmi = weight / height / height;
        if (mode === 2) {
          if (_.isNaN(bmi)) {
            check012.value = null;
          } else {
            check012.value = (bmi < 18.5) ? 1 : 0;
          }
        }
        if (_.isNaN(bmi)) {
          return '';
        }
        // return bmi.toFixed(1) + "[kg/㎡] = " + weight.toString() + "[kg] ÷ " + height.toString() + "<sup>2</sup>[㎡]"
        return bmi.toFixed(1) + "[kg/㎡] = 体重[kg] ÷ 身長<sup>2</sup>[㎡]"
      };
    },
    /**
     * チェック・再チェック判定
     * @param objerct item 対象項目
     * @param objerct check チェックオブジェクト
     */
    JudgeCheck: function() {
      return function(item, check) {
        if (item.key == 'check012') { // 栄養状態
          return this.JudgeDisp(item.key, check);
        }
        if (item.key == 'judge_i_iv') { // 生活機能全般 判定
          let keys = [
            ["judge01_20", "judge06_10", "judge11_12", "judge13_15"],
            ["judge16_17", "judge18_20", "judge21_25"],
          ];
          let exist = 0; // 該当なし
          let notexist = 0;
          for (let i = 0; i < keys.length; i++) {
            for (let key of keys[i]) {
              let val = _.get(_.find(check, (x) => x.key === key), 'value');
              if (2 === val) {
                exist++; // 該当ありかどうか
              } else if (1 === val) {
                notexist++;
              }
            }
          }
          if (exist) {
            item.value = 2;
          } else if (notexist) {
            item.value = 1;
          } else {
            item.value = 0;
          }
          return this.JudgeDisp(item.key, check);
        }

        // 判定
        let judges = {
          "judge01_20" : { // No.35 1〜20の点数
            keys: ["check001", "check002", "check003", "check004", "check005", "check006", "check007", "check008", "check009", "check010",
              "check011", "check012", "check013", "check014", "check015", "check016", "check017", "check018", "check019", "check020",],
            boundary: 10,
            suffix: ":10項目以上に該当あり）"
          },
          "judge06_10" : { // No.36 運動不足予防点数
            keys: ["check006", "check007", "check008", "check009", "check010",],
            boundary: 3,
            suffix: ":3項目以上に該当あり）",
          },
          "judge11_12" : { // No.37 栄養改善点数
            keys: ["check011", "check012",],
            boundary: 2,
            suffix: ":2項目に該当あり）",
          },
          "judge13_15" : { // No.38 口腔ケア予防点数
            keys: ["check013", "check014", "check015",],
            boundary: 2,
            suffix: ":2項目以上に該当あり）",
          },
          "judge16_17" : { // 閉じこもりの恐れ
            keys: ["check016", "check017",],
            boundary: 1,
            suffix: ":16に該当あり）",
          },
          "judge18_20" : { // 認知機能低下の恐れ
            keys: ["check018", "check019", "check020",],
            boundary: 1,
            suffix: ":1項目以上に該当あり）",
          },
          "judge21_25" : { // うつ傾向の可能性あり
            keys: ["check021", "check022", "check023", "check024", "check025",],
            boundary: 2,
            suffix: ":2項目以上に該当あり）",
          }
        };
        if (!_.isNil(judges[item.key])) {
          let judge = judges[item.key];
          let total = 0;
          if (item.key == "judge16_17") {
            let val = _.get(_.find(check, (x) => x.key === 'check016'), "value");
            total = !_.isNil(val) ? val : -1;
          } else {
            try {
              for (let key of judge.keys) {
                let value = _.get(_.find(check, (x) => x.key === key), "value");
                if (!_.isNil(value)) {
                  total += value;
                } else {
                  total = -1; // 未入力あり。
                  break;
                }
              }
            } catch (err) {
              //
            }
          }
          let result = "未入力あり";
          if (total === -1) {
            item.value = 0; // 未入力あり
          } else {
            item.value = (total >= judge.boundary) ? 2 : 1; // 1:該当なし
            result = _.toString(total);
            try {
              result += " " + item.options.suffix;
            } catch (err) {
              //
            }
            if (item.value == 2) { // 該当あり
              return "該当あり（" + result + judge.suffix;
            }
            return "該当なし（" + result + "）"
          }
          return result;
        }  
        return item.key;
      }
    },
    /**
     * 調査票・チェック判定
     * @param objerct item 対象項目
     */
    Judge: function() {
      return function(item) {
        return this.JudgeCheck(item, this.checklists['check']);
      }
    },
    /**
     * 該当・非該当表示
     * @param objerct val 値
     */
    JudgeDisp2: function() {
      return function(val) {
        let arr = [
          "<mark class='caution'>未入力あり</mark>", // 0
          "<mark class='normal'>非該当</mark>", // 1
          "<mark class='alert'>該　当</mark>", // 2
        ];
        return (arr[val] !== undefined) ? arr[val] : "-";
      }
    },
    /**
     * チェック判定表示
     * @param objerct key キー
     * @param objerct check チェックオブジェクト
     */
    JudgeDisp: function() {
      return function(key, check) {
        // 判定結果を表示する場合
        let val = _.get(_.find(check, (x) => x.key === key), "value", 0);
        let messages = [];
        switch (key) {
          case 'judge_i_iv':
            messages = [
              "未入力あり", // 0
              "<mark class='normal'>該当項目なし</mark>", // 1
              "<mark class='alert'>該当項目あり</mark>", // 2
            ];
            return messages[val] || "-";
          case 'check012': // 栄養状態
            messages = [
              "<mark class='normal'>BMIが 18.5以上です</mark>", // 0
              "<mark class='alert'>BMIが 18.5未満です</mark>", // 1
            ];
            return messages[val] || "未入力あり";
          default:
            return this.JudgeDisp2(val);
        }
      };
    },
    customCheckList: function() {
      return this.checklists['check'];
    },
  },
  watch: {
    /**
     * tokenの値が変化したときの処理
     * @param {String} val 新しい値
     * @param {String} old 古い値
     */
    async token(val, old) {
      if (_.isNil(old) && !_.isEmpty(val)) {
        await this.checkHistoryRoute({
          ..._.pick(this.$route.params, ["guestId"
          ]),
          hash: this.$route.hash,
        });
      }
    },
    /**
     * ログインダイアログの表示
     * @param {boolean} value 表示するかどうか
     */
    async visibleLoginDialog(value) {
      if (value === false) {
        let result = await this.$store.dispatch("selectGuest", {
          guestId: this.$route.params.guestId,
        });
        if (result === false) {
          // ユーザが存在しないため、一覧に戻る
          await this.$router.push("/guests/history").catch(() => {});
        }
      }
    },
    /**
     * タブ変更時の処理
     * @param {number} selectedIdx 選択されたタブのインデックス
     */
    async tabIdx(selectedIdx) {
      let currentIdx = _.indexOf(
        this.typeNames,
        _.trimStart(this.$route.hash, "#")
      );
      if (selectedIdx === this.defaultTab && currentIdx === -1) {
        await this.$router
          .push(`#${_.nth(this.typeNames, this.defaultTab)}`)
          .catch(() => {});
      }
    },
    /**
     * チェックリスト選択時の処理
     * @param {Object} checkList チェックリスト
     */
    selectedCheckList: {
      handler(checkList) {
        if (checkList) {
          // 選択時にロードする
          this.loadChecklists();
        } else {
          //選択されたチェックリストがない場合は初期化する
          _.forEach(this.checklists['check'], (item) => {
            item.value = [null, null, null];
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    document.documentElement.style.setProperty('--key-color', config.system_key_color);
    this.isHistoryClosed = this.display.xs.value;
  },
  methods: {
    /**
     * 履歴ロード
     * @param {*} guestId ゲストID 
     */
    async loadHistory(guestId) {
      // 履歴一覧を取得
      await this.$store.dispatch("updateHists");
      // 利用者を選択
      await this.$store.dispatch("selectGuest", { guestId });
      await this.$store.dispatch("updateCheckLists");
      this.selectedHist = _.find(this.activeHists, (x) => x.guestId == guestId);
      // 初期化
      _.forEach(this.customCheckList, (item) => {
        if (Object.keys(item).indexOf('value') !== -1 && !Array.isArray(item.value)) {
          item.value = [null, null, null];
        }
      });
      // 履歴バッファ なければ初期化
      if (_.isUndefined(this.result_datas)) {
        this.result_datas = [null, null, null, null, null]; // グラフ
      }
      let reg_checklists = [null, null, null];
      let reg_results = [null, null, null, null];
      // 利用者IDからチェックリストを取得 今年、去年、一昨年、3年前 の4年分
      for (let i = 0; i <= 3; i++) {
        let checklist = this.selectedHist.checklist[i];
        if (checklist && !_.isUndefined(checklist)) {
          if (i <= 2) {
            reg_checklists[i] = _.cloneDeep(checklist.checklists);
          }
          if (checklist.results && checklist.results.data) {
            reg_results[i] = _.cloneDeep(checklist.results.data);
            if (i == 0) {
              this.predict = _.cloneDeep(checklist.results.predict);
            }
          }
        }
      }
      this.reg_checklists = reg_checklists;
      this.reg_results = reg_results;
      await this.loadChecklists();
      this.isHistoryLoading = false;
    },
    /**
     * 遷移時におけるURLの検査
     * @param {*} guestId 利用者ID
     * @param {*} hash ハッシュ
     * @returns {boolean} 遷移を許可するかどうか
     */
    async checkHistoryRoute({ guestId, hash } /*, isEnter = false */) {
      // 1. 利用者確認
      if (_.isNil(guestId)) {
        // IDが不正：遷移を拒否
        return false;
      }
      await this.$store.commit("setFiscalYear", Number(dayjs().subtract(3, 'M').format('YYYY')));
      if (!_.some(this.activeGuests, (x) => x.guestId === guestId)) {
        // 利用者IDがキャッシュに存在しない：再読込
        this.isGuestsLoading = true;
        await this.$store.dispatch("updateGuests");

        // 再確認
        if (!_.some(this.activeGuests, (x) => x.guestId === guestId)) {
          // 発見できず：遷移を拒否
          _.delay(() => {
            this.$router.replace(`/guests/history`).catch(() => {});
          }, 500);
          return false;
        }
      }
      //setTimeout(async () => { await this.loadHistory(guestId) }, 0);
      // 3. hashの処理
      if (_.isEmpty(hash)) {
        this.tabIdx = this.defaultTab;
      } else {
        let idx = _.indexOf(this.typeNames, _.trimStart(hash, "#"));
        if (idx == -1) {
          this.tabIdx = this.defaultTab;
        } else {
          this.tabIdx = idx;
        }
      }
      this.isHistoryLoading = true;
      await this.loadHistory(guestId);
      this.isHistoryLoading = false;
      this.isGuestsLoading = false;
      // 遷移を許可
      return true;
    },
    /**
     * タブ選択
     * @param {*} idx インデックス
     */
    async tabSelect(idx) {
      let currentIdx = _.indexOf(
        this.typeNames,
        _.trimStart(this.$route.hash, "#")
      );
      if (currentIdx !== idx) {
        await this.$router
          .push(`#${_.nth(this.typeNames, idx)}`)
          .catch(() => {});
      }
    },
    /**
     * チェックリストからチェックリストの読み込み（UIへのバインド）
     */
    loadChecklists() {
      for (let item of this.customCheckList) {
        if (Object.keys(item).indexOf('value') === -1) {
          item.value = [null, null, null];
          continue; // value がなければ処理しない。
        }
        for (let i = 0; i <= 2; i++) {
          if (this.reg_checklists[i]) {
            item.value[i] = this.reg_checklists[i][item.key];
          }
        }
      }
      this.result_datas = [null, null, null, null, null];
      for (let i = 0; i <= 3; i++) {
        if (this.reg_results[i]) {
          let idx = 3 - i;
          if (idx >= 0) {
            this.result_datas[idx] = this.reg_results[i][0] != -1 ? this.reg_results[i][0] : null;
            if (!this.result_datas[idx + 1]) {
              this.result_datas[idx + 1] =  this.reg_results[i][1] != -1 ? this.reg_results[i][1] : null;
            }
          }
        }
      }
    },
    /**
     * 入居者様情報の更新
     */
     async refresh() {
      this.isHistoryLoading = true;
      this.loadChecklists();
      this.isHistoryLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  div#care-plan-guest-name.py2{
    padding: 0 !important;
  }
  h2#guest-name {
    background-color: #eaeff1;
    padding: 0.1rem 0.1rem 0.5rem 0.6rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: -1px -1px 1px 0 #f1f7ff, inset 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
  b {
    font-size: 1.2rem;
    text-shadow: 1px 1px 2px #b0bbc1;
    line-height: 1.1;
  }

  .care-plan-dt {
    color: #ffffff;
    background-color: var(--key-color);
    padding: 0.1rem 0.2rem 0.2rem 0.3rem;
    font-weight: bold !important;
    border: 2px solid #fff;
    border-radius: 6px;
    box-shadow: 3px 2px 4px 0px rgb(137 150 172 / 30%);
    white-space: nowrap;
    text-shadow: 1px 1px #426676;
    font-size: 0.8rem;
    vertical-align: middle
  }
}
@import "../scss/conditiongraph.scss";
@import "../scss/comparing.scss";

:deep(div.v-list-item__content){
  display:flex;
}
:deep(#mainTabArea button ){
  font-size: 1.1rem;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  line-height:1;
  width: auto;
  background-color:#fbfbfbee;
  box-shadow: 0px -1px 3px #ddd inset;
  &.v-btn.text-primary {
    color: var(--key-color) !important;
    background-color: #dcf1f8 !important;
    border-right-color: #ddd !important;
    box-shadow:none;
  }
  span div.v-tab__slider {
    color:var(--key-color);
    height: 3px;
  }
}
.careplan {
  &__container {
    flex-wrap: nowrap;
  }

  &__timeline {
    border-radius: 0 5px 5px 0;
    border-left: 1px solid $color_gray_d !important;
    width: 160px;

    &--xs {
      width: 160px;
    }

    transition: $transition_width;

    &.close {
      width: 55px;

      .careplan__timeline-content {
        overflow-y: hidden;
      }
    }

    &-container {
      position: sticky;
      top: 0;
      max-height: 100vh;

      flex-wrap: nowrap;
      flex-direction: column;
    }

    &-content {
      overflow-y: auto;
      flex: auto;
    }

    .v-list-item {
      &__title {
        font-family: $font-roboto;
        font-weight: normal;
        font-size: 0.9rem;
      }

      &--active .v-list-item__title {
        font-weight: bold;
      }
    }
  }
}

.togglebutton__item {
  min-width: 100px !important;

  &--selected {
    background-color: $color_primary !important;
    color: white !important;
  }
}

.conditionlist {
  &__groupname {
    position: sticky;
    top: 0;
    z-index: 2;

    margin-top: -0.5rem;
    margin-bottom: 0;
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &__table {
    width: 100%;
    margin-bottom: 3rem;
    border-collapse: collapse;
    tr:hover {
      th,
      td {
        background-color: $color_gray_f;
      }
    }

    th {
      text-align: left;
      padding: 0.5rem 1rem 0.5rem 0;
      width: 350px;
      color: $color_gray;
      border-bottom: 1px solid $color_gray_e;
    }

    td {
      color: $color_gray;
      padding: 0.5rem 1rem 0.5rem 0;
      border-bottom: 1px solid $color_gray_e;
    }

    &--small {
      display: block;

      tr {
        display: block;
      }

      th,
      td {
        display: block;
        max-width: 100%;
      }
      th {
        padding: 0.75rem 0 0 0;
        border-bottom: 0;
      }
      td {
        padding: 0 0 0.75rem 0;
      }
    }
  }
}


:deep(.v-expansion-panel-title ){
    background-color: $color_primary;
    color: #fff;
    font-size: 1.6rem !important;
    font-weight: bold;
}

:deep(.mdi-chevron-down::before ){
    color: #fff;
}

.v-application .title {
    font-size: 1.4rem;
    font-weight: bold;
}

:deep(hr.page-break ){
  visibility:hidden;
  padding:0;
  margin:0;
  height:0;
}
:deep(table.conditionlist__table tr ){
  th,td,span,
    mark{
    font-size: 1.3rem;
  }
  &.points_text{
    border-top: 1px solid #ccc;
    background: #eee;
    th,
    td span {
      color: #0B5882;
    }
  }
  &.alert {
    background-color: #FEDAEF;
    th,
    td span {
      color: #AE0900;
    }
  }
  &.height th,
  &.height td,
  &.weight th,
  &.weight td,
  &.__bmi th,
  &.__bmi td span,
  &.other th{
    font-weight:normal;
    font-size: 1.2rem;
  }
  &.height th,
  &.weight th,
  &.__bmi th{
    padding-left:3rem;
  }
  &.height th,
  &.height td,
  &.weight th,
  &.weight td,
  &.other th,
  &.other td {
    border-bottom:0;
  }
}

:deep(.careplan__container .v-tab){
  font-weight:bold;
  font-size: 1rem !important;
}

:deep(div#judgement ){
  /* 判定エリア専用 */
  .v-expansion-panel-content__wrap {
    padding:1.3rem;
    .row .col {
      padding:0.3rem !important;
      mark {
        white-space: nowrap;
        box-shadow: 1px 1px 2px #777;
      }
    }
  }
}
:deep(table.conditionlist__table tr ){
  th.indent{
    padding-left: 3rem !important;
  }
  th.indent:not(.last),
  td.indent:not(.last) {
    border-bottom: none !important;
  }
  th.life_title,
  td.life_title{
    border-bottom: none !important;
  }
  td{
    padding: 1rem !important;
    mark {
      white-space: nowrap;
      box-shadow: 1px 1px 2px #777;
      font-size: 1rem;
    }
  }
}
:deep(div.row.no-gutters>div.col>div.row.life ){
  margin: 0 !important;
  table {
    margin-bottom: 0;
  }
  div.col.col-12 {
    padding: 0;
    margin: 1em 0;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
  div.col>h3.conditionlist__groupname {
    background: #218FD3 !important;
    color: #fff;
    font-size: 1.25rem !important;
    font-weight: bold;
    display: block;
    padding: 16px 24px;
    border-radius: 5px 5px 0 0;
  }
}
:deep(.v-expansion-panel-content__wrap){
  /* 資料のダウンロードエリア専用 */
  #result .col span{
    font-size:1.3rem;
  }

  padding:1rem 1.5rem;

  div.row {
    margin: 0 !important;
    div{
      font-weight:bold;
    }
  }

  h3{
    background: $color_section_bg;
    padding: 1rem 1.6rem 1rem 1.5rem !important;
    border: 0;
    margin:1rem 0 0 0 !important;
    font-size:1.3rem;
  }
  h3.emphasis {
    font-size: 1.6rem;
    color: #212a44 !important;
    border-left: 6px solid $color_primary !important;
    background: #f4f4f4 !important;
  }
  ul{
    margin-left: 1rem;
    li {
      list-style: none;
      &.first {
        color: #333;
        font-weight:bold;
      }

      &.second {
        margin-top:1em;

          border: 1px solid #ccc;
          padding:1em;
          border-radius: 6px;

        p,ol {
          width:96%;
        }
        b {
          background-color: #777;
          padding: 0.3em 0.5em;
          display: block;
          color: #fff;
          border-radius: 6px;
          text-align: center;
          position: absolute;
        }
        ol {
            display: block;
            background: #eee;
            padding: 1em 0.3em 0.6em 0.6em;
            border-radius: 6px;
        }
      }
    }
  }
  h4 {
    mark {
      border:1px solid #826804;
    }
  }
  h5 {
    font-size: 1.4rem;
    position: relative;
    padding: 1.5rem 2rem 1.5rem 150px;
    min-height: 170px;
    border-radius: 10px;
    background: #FFECE6;
    border: 4px solid #FFDED3;
    margin-bottom: 1.2rem !important;
  }

  h5:before {
    position: absolute;
    top: 17px;
    left: 5px;
    width: 0;
    height: 0;
    transform: scale(0.4);
    content: url(../assets/care1.png);
  }

  hr {
    border-top:1px solid $color_section_border;
    margin-top:1rem;
    margin-bottom:2rem;
  }
  div div {
    font-size: 1.3rem;
  }

  div div.v-col{
    padding:0 !important;
  }

  div div strong {
    font-size: 1.3rem;
    font-weight: bold;
    color: #b8545d;
  }

  .v-row .v-col section {
    border: 1px solid $color_section_border;
    border-radius: 0.3em;
    background-color: $color_section_bg;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
    padding: 1.1rem ;
    margin-bottom: 1.2rem !important;
  }
  .v-row .v-col section ul li{
    font-size: 1.3rem;
  }
}
div.analyse h3.conditionlist__groupname{
  border-bottom: 2px solid #999;
  padding-bottom:0 !important;
  margin-bottom: 1rem;
}

@media print {

  body {
      width:auto !important;
  }
  /* 印刷時は折りたたんでいても強制展開 */
  :deep(.v-expansion-panel-text ){
    display:block !important;
  }
  /* 基本生活/身体精神選択時の印刷設定 */
  div.careplan.pa-2>div.v-sheet>div.v-container {
    div.v-row.careplan__container{
      div.v-col>div.v-row>div.v-col>div.v-row {
        break-inside: auto;
        h3.conditionlist__groupname {
          margin-top:0;
          margin-bottom: 0.8rem;
          font-size:1.4rem;
          border-left: 6px solid var(--key-color) !important;
          padding :0.3rem 0 0.3rem 0.8rem;
        }
        table.conditionlist__table {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: 0;
          tr {
            width: 50%;
            display: flex;
            th {
              padding: 0.5rem 1rem 0.5rem 0.5rem;
              width: 50%;
              color: #000;
              border: 2px solid #ddd;
              border-radius: 6px;
              margin-bottom: 0.4rem;
              font-size:1.2rem;
              font-weight: bold;
            }
            td {
              color: #000;
              padding: 0.5rem 0 0.5rem 0.5rem;
              width: 50%;
              border-bottom: 0;
              font-size:1.2rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  #careplan-edit-btn-outer {
    text-align: right !important;
    padding-right: 0.5rem !important;
  }
}
/* ----------------------スマホ向け設定 ---------------------- */
@media screen and (max-width: 600px) {
  :deep(.v-btn ){
    letter-spacing: 0 !important;
  }
  :deep(table.conditionlist__table){
  border-bottom: 1px solid #bbb;

  tr:not(.ew-memo):not(.opt-ew-memo) {
    border-left: 0;
    border-top: 0;
    th {
      background-color: #fafafa;
      border-right: 0;
      border-bottom: 1px solid #bbb;
      text-shadow: 1px 1px  #fff;
      color: #000;
      padding:10px 0!important;;
    }
    th,td{
      padding-left: 1rem!important;
      font-size: 1rem;
    }
    td{
      border-right: 0;
      border-bottom: 0;
    }
  }
} 
  #careplan-edit-btn-outer {
    text-align: left !important;
    padding-right: 0 !important;
  }
  #careplan-create-btn {
    padding: 0 0.25rem !important;
  }
  :deep(div.v-expansion-panel div.v-expansion-panel-text div.v-expansion-panel-text__wrapper ){
    padding: 0 8px 8px 8px !important;
  }
  h3.conditionlist__groupname {
    margin-top: 1rem !important;
  }
  .v-expansion-panel-content__wrap{ 
    ul li {
      padding-left: 1em;
    }
    ul, 
    h4 {
      margin-left: 0 !important;
    }
  }
  .v-application ul, .v-application ol {
    padding-left: 0 !important;
  }
  /* スマホ表示時のケアプラン「閲覧モード」の「タブメニュー（基本生活～比較）」 の各設定*/
  :deep(div.v-slide-group__content){
    display: block;
    div.v-tab{
      font-size: 1rem !important;
      line-height: 1rem;
      min-width: 70px;
      padding:0 4px;
      height:50px;
      display: table-cell;
      vertical-align: middle;
      span{
        font-size: 1rem !important;
        display: inline-block;
      }
    }
  }

  /* スマホ表示時のケアプラン「閲覧モード」の「基本生活」「身体精神」「分析」 のテーブル設定*/
  :deep(table.conditionlist__table ){
    margin-bottom: 1rem;
    border:1px solid #fff;
    box-shadow: none;
    & tr {
        background-color: #fff;
      th {
        border-left: 3px solid $color_primary;
        border-right-color: #ffff;
        padding-top:0 !important;
        margin-top:0.8rem;
        padding-left:0.8rem;
        font-size: 1.1rem;
      }
      td {
        font-size: 1rem;
        padding-left:1rem;
        padding-top:0.5rem;
      }
    }
  }

  /* スマホ表示時のケアプラン「閲覧モード」の「分析」 の各設定*/
  :deep(div.analyse ){
    padding-left:8px !important;
    padding-right:8px !important;
    button.v-expansion-panel-title {
      padding: 0 12px;
      div.title {
        font-size: 1rem !important;
        line-height: 1.1rem !important;
      }
    }
    /* AS/SS機能 */
    .v-expansion-panel-text__wrapper div div {
      font-size: 1rem !important;
      strong {
        font-size:1rem !important;
      }
      h3 {
        font-size: 1.1rem !important;
      }
    }
    section {
      padding: 1rem;
      h4 {
        font-size: 1.1rem;
      }
      ul {
        padding-left: 18px;
      }
      ul li{
        font-size: 1rem !important;
      }
      ul li b {
        font-size: 1rem !important;
      }
    }
    /* けあワンのワンポイントエリア */
    h5 {
      font-size: 1rem !important;
      padding:0.8rem 0.5rem 0.8rem 100px;
      min-height: 140px;
    }
    /* けあワンの画像サイズ、位置設定 */
    h5:before {
      transform: scale(0.25);
      top: 12px;
      left: 5px;
    }
  }
  /* 比較:スマホ表示時の余白調整 */
  div.v-container--fluid>div.v-row>div.v-col {
    div.v-row.v-row--no-gutters>div.pa-6{
      div.v-row {
        margin:0;
      }
    }
  }
}
</style>

<style lang="scss">
.comparing-selected {
  @media print {
    -webkit-print-color-adjust: exact;
  }
}

.comparing-chart__item {
  canvas {
    @media print {
      margin: 0 auto;
    }
  }
}

section ul li b {
  font-size: 1.3rem;
  color:#b8545d;
}

/* -- printout stylesheet --------------------------------------------------- */
@media print {
  header {
    display:none !important;
  }
  footer {
    display:none !important;
  }
  .wrap_footer_ai_ver {
    display:none !important;
  }
  .v-application--wrap {
    min-height: auto;
  }
  .tooltip {
    visibility:hidden !important;
  }
  .hide--overflow {
    overflow: hidden;
  }
  h2,
  .v-application h2>span.text-subtitle-1 {
    font-size: 1.4rem !important;
    font-weight: bold;
  }
  .v-main{
    padding: 0 !important;
  }
  .v-application .elevation-5 {
    -webkit-box-shadow:none !important;
    box-shadow:none !important;
    border: none !important;
  }
  .v-application .pa-2 {
      padding: 0 !important;
  }
  .v-application .pa-6 {
    padding: 0 !important;
  }

  div.v-container{
    padding: 0!important;
  }

  .page-break {
    page-break-after: always;
  }

  .v-dialog__content {
    display: none !important;
  }

  /* 「分析」画面の印刷設定 */
  body div.analyse {
    * {
      font-size: 20px !important;
      box-shadow: none;
    }
    .text-h6 {
      font-weight: bold;
    }
    .v-expansion-panel-title__icon{
      display:none;
    }

    hr {
      visibility:hidden;
      padding:0 !important;
      margin:0 !important;
      height:0 !important;
      break-after: auto;
    }
    hr.my-6 {
      page-break-after: avoid !important;
    }
    h4 {
      margin-bottom: 4px !important;
      font-size:18px !important;
    }

    div.v-col{
      padding: 4px;
    }

    div.v-col-12{
      padding: 12px 0 0 0;
    }
    
    .title{
      font-size:20px !important;
      font-weight: bold;
      padding:0 4px !important;
    }

    .v-expansion-panels {
      display:block !important;
    }

    .v-expansion-panel::before {
      -webkit-box-shadow:none !important;
      box-shadow:none !important;
      border: none !important;
    }
    .v-expansion-panel-title {
      color:#000!important;
      font-weight:bold;
      background-color: inherit !important;
      border:2px solid #333;
      margin:0!important;
      padding:4px 6px!important;
    }

    .v-expansion-panel {
      margin-top: 8px !important;
    }
    .v-expansion-panel-text {
      font-size:20px !important;
    }
    .v-expansion-panel-text__wrapper {
      padding: 0 !important;
      margin-bottom: 0 !important;
      h5 {
        margin-top: 0.6rem !important;
        margin-bottom: 0 !important;
      }
      .v-row .v-col section {
        margin-top: 8px !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        padding:8px 10px 14px 10px !important;
        margin-bottom: 10px !important;
      }
      p.analysis_text {
        padding: 1rem 220px 0 1rem;
      }
    }
    .v-expansion-panel > .v-expansion-panel-title {
      min-height: 40px;
    }
    .v-expansion-panel-text__wrapper h3 {
      margin: 0 !important;
      background: none !important;
    }
    .v-expansion-panel-text__wrapper h3.emphasis {
      margin: 0.8rem 0 !important;
    }
    div.v-row div.v-col div h3 {
      font-size:20px !important;
      padding: 6px !important;
    }
    ul, ol {
      padding-left: 1.5rem !important;
    }
    li {
      padding-left: 0;
      margin-top: 8px;
      font-size:18px !important;
      line-height: 18px;
    }
    .text-subtitle-1 {
      font-size: 20px !important;
    }
    .v-row {
      margin:0 !important;
    }
    .footer_ai_ver {
      display: none;
    }
    span.text-caption {
      display: none;
    }
  }
  /* 「比較」画面の印刷設定 */
  div.care-plan-edit.pa-2,
  div.comparing_plans {
    &.v-col.v-col-12,
    &>div.v-container.pa-0.v-container--fluid>div.v-row>div.v-col.v-col-12>div.mb-6.v-sheet.elevation-5{
      padding:8px !important;
      div.mb-6.v-sheet>div.v-container,
      div.v-container.pa-6.v-container--fluid{
        padding: 0 !important;
        &>div.v-row>div.pa-0>div.v-row:nth-of-type(1) {
          margin: 0;
        }
        &>div.v-row>div.pa-0>div.v-row.comparing-selected {
          margin:0 !important;
          &>div {
            display:block !important;
            margin: 0 0 10px 0;
            min-width:100%;
            padding:10px !important;
            background-color: #FCFCFC;
            border:1px solid #ddd;
            border-radius: 6px;
          }
        }
      }
    }
    div.v-row.comparing-chart.justify-center {
      display:block;
      .comparing-chart__item {
        margin: 0;
        padding:0;
        max-width: 90%;
      }
    }
  }
}
:deep(button#home-refresh) {
  margin-top: 16px;
  min-width: auto !important;
  max-width: 3rem;
}

</style>