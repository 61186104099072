<template>
  <div id="chat_help_wrap">
    <v-btn
      id="advice_gpt_btn"
      fixed
      style="
      bottom:40px;
      right:3px;
      z-index:9;
      height: auto;
      width: 130px;
      min-width: auto;
      padding: 0;
      background:inherit;
      box-shadow:none;
      position:fixed;
      display: block;
      "
      @click="openPopup"
    >
      <v-img :src="require('../assets/care_one_help.svg')" />
    </v-btn>

    <!-- ポップアップウィンドウ -->
    <v-dialog
      id="advice_gpt_dialog"
      v-model="dialog.visible"
      :class="{'sp_mode': display.xs.value}"
      persistent
    >
      <v-card id="advice_gpt_window">
        <!-- タイトルバー -->
        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title>SOIN AI Chat</v-toolbar-title>
          <v-btn
            id="close_pop_up"
            icon
            class="ml-auto"
            @click="closePopup"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- コンテンツ -->
        <v-card-text v-if="dialog.mode==0">
          <div id="help_text_area">
            <v-img
              :src="require('../assets/care_one_icon.svg')"
              max-width="150"
              style="margin-right: 0.5rem;"
            />何かお困りでしょうか？<br>該当する項目を以下より選択してください
          </div>

          <v-list id="help_list">
            <v-list-item
              v-if="activeCheckLists[0].aiAnalysis"
              @click="()=>{dialog.mode=1}"
            >
              <v-list-item-title
                id="help_list1"
                style="font-weight:bold; font-size:1.5rem; margin-bottom:0.5rem; padding:0.2rem"
              >
                ・{{ selectedGuest.lastName }} {{ selectedGuest.firstName }} 様のケアプランについて 
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="()=>{dialog.mode=2}">
              <v-list-item-title
                id="help_list2"
                style="font-weight:bold; font-size:1.5rem; margin-bottom:0.5rem; padding:0.2rem"
              >
                ・日常生活に関するご質問
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text
          v-else-if="dialog.mode > 0"
          style="margin-top:1rem"
        >
          <h3
            v-if="dialog.mode == 1"
            id="ques1"
            class="d-flex justify-space-between"
          >
            {{ selectedGuest.lastName }} {{ selectedGuest.firstName }} 様に関する質問を入力してください(例：歩行を改善したい)<v-btn
              v-if="!dialog.ai_answer_end && !dialog.isLoading"
              id="back_btn"
              icon
              @click="()=>{dialog.mode=0}"
            >
              <v-icon>mdi-arrow-left</v-icon>戻る
            </v-btn>
          </h3>
          <h3
            v-else-if="dialog.mode == 2"
            id="ques2"
            class="d-flex justify-space-between"
          >
            日常生活に関する質問を入力してください（例：無理なく体力づくりをしたい）<v-btn
              v-if="!dialog.ai_answer_end && !dialog.isLoading"
              id="back_btn"
              icon
              @click="()=>{dialog.mode=0}"
            >
              <v-icon>mdi-arrow-left</v-icon>戻る
            </v-btn>
          </h3>
          <vue-element-loading
            :active="dialog.isLoading"
            color="#3e8ad6"
            style="background-color: inherit;"
          />
          <div
            v-if="dialog.history.length > 0"
            id="ai_ques_area"
            ref="scrollContainer"
          >
            <v-card
              v-for="(history, i) in dialog.history"
              :key="i"
              style="margin-bottom:0.5rem"
            >
              <v-card-title
                v-if="history && 'user_question' in history"
                variant="outlined"
                readonly
                class="v-card__title ai_ques_field_old"
              >
                <div><b> あなたの質問 </b>&nbsp;{{ history['user_question'] }}</div>
              </v-card-title>
              <v-card-text
                v-if="history && 'ai_answer' in history"
                rows="15"
                readonly 
                variant="outlined"
                style="font-size:1rem"
                class="ai_ans_field_old"
              >
                <div id="ai_ans_area">
                  <b class="ai_answer_ico">AIからの回答</b><br>
                  <span
                    v-html="`${history['ai_answer']
                      .replace(/###### (.*?)\<br\>/g, '<h6>$1</h6>\n')
                      .replace(/##### (.*?)\<br\>/g, '<h5>$1</h5>\n')
                      .replace(/#### (.*?)\<br\>/g, '<h4>$1</h4>\n')
                      .replace(/### (.*?)\<br\>/g, '<h3>$1</h3>\n')
                      .replace(/## (.*?)\<br\>/g, '<h2>$1</h2>\n')
                      .replace(/# (.*?)\<br\>/g, '<h1>$1</h1>\n')
                      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')}`"
                  />
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>

        <v-card-text
          v-if="dialog.mode!==0"
          id="outer_ai_ques_field"
        >
          <v-row>
            <v-col style="display: flex; flex-direction: row; position: relative;">
              <v-textarea
                id="ai_ques_field"
                v-model="dialog.current_ques" 
                :label="dialog.label"
                rows="1"

                :disabled="dialog.ai_answer_end"
                maxlength="400"
                style="width:90%;"
                variant="outlined"
                auto-grow
              />
              <v-btn
                id="submit_btn"
                style="padding: 0 2px; position: absolute; right: 40px; transform: translateY(50%);"
                :disabled="dialog.ai_answer_end || dialog.isLoading"
                @click="callAdviceGpt"
              >
                送信
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              style="padding-top: 0;"
            >
              <!-- 音声入力ボタンを追加 -->
              <v-btn
                v-if="isSpeechRecognitionSupported"
                id="voice_input_btn"
                :color="isRecognizing ? 'red' : 'primary'"
                @click="startVoiceRecognition"
              >
                <v-icon>{{ isRecognizing ? 'mdi-microphone-off' : 'mdi-microphone' }}</v-icon>{{ isRecognizing ? '声を認識中' : '声で入力' }}
              </v-btn>
              <v-btn
                id="clear_btn"
                style="margin-left: 6px;"
                color="secondary"
                @click="clearInput"
              >
                <v-icon>mdi-eraser</v-icon>入力消去
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div id="chat_caution">
                ※回答が返ってこない場合は <a
                  href="javascript:void(0)"
                  @click="initDialog"
                >こちら</a>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import VueElementLoading from "vue-element-loading";
import { useDisplay } from 'vuetify'
export default {
  name: "EwChatHelp",
  components: {
    VueElementLoading
  },
  props: {
    hint: {
      type: String,
      default: ''
    },
    certifCareLevel: {
      type: String,
      default: ''
    },
  },
  emits: ["input"],
  setup() {
    const display = useDisplay();
    return { display };
  },
  data() {
    return {
      dialog: {
        visible: false,
        title: "",
        /** 質問内容 */
        current_ques: "",
        history: [],
        ai_answer_end: false,
        label: "AIへの質問",
        isLoading:false,
        error_mess: "",
        mode:0
      },
      isSpeechRecognitionSupported: false,
      recognition: null,
      isRecognizing: false,
      finalTranscript: '', // 確定したテキストを保持
      isClearing: false,   // クリア中であることを示すフラグ
      // 置換パターンの定義
      replacementPatterns: [
        { pattern: /車椅子に異常/g, replacement: '車椅子に移乗' },
        { pattern: /車椅子に移動/g, replacement: '車椅子に移乗' },
        { pattern: /車椅子への異常/g, replacement: '車椅子への移乗' },
        { pattern: /車椅子への移動/g, replacement: '車椅子への移乗' },
        { pattern: /排雪/g, replacement: '排泄' },
        { pattern: /認証/g, replacement: '認知症' },
        { pattern: /デーサービス/g, replacement: 'デイサービス' },
        { pattern: /老犬/g, replacement: '老健' },
        { pattern: /職層/g, replacement: '褥瘡' },
        { pattern: /ソワン /g, replacement: 'そわん' },
        { pattern: /ソアン /g, replacement: 'そわん' },
        { pattern: /スワン /g, replacement: 'そわん' },
        { pattern: /そわん /g, replacement: 'そわん' },
      ],
      confirm: {
        visible: false
      },
    };
  },
  computed: {
    /**
     * dialog.current_quesを監視します
     * @return dialog.current_ques
     */
    currentQuestion() {
      return this.dialog.current_ques;
    },
    ...mapGetters([
      "activeCheckLists",
      "selectedCheckList",
      "selectedGuest"
    ]),
    /**
     * 追加可能をチェック
     * @return true:OK
     */
    canExec() {
      if (_.isNil(this.dialog.memo) || _.isEmpty(this.dialog.memo)) {
        return false;
      } else if (this.dialog.memo.length > 400) {
        this.errorHandler("400文字以内で入力してください。(改行も文字数に入ります)");
        return false;
      } else {
        this.errorHandler("");
      }
      return true;
    }
  },
  watch: {
    value: {
      handler(val) {
        this.value_ = val || [];
      },
      immediate: true
    },
    value_(val) {
      this.$emit("input", val);
    },
    /**
     * currentQuestionが変更されたときに実行される関数
     * @param newVal 新しい値
     */
    currentQuestion(newVal) {
      this.dialog.current_ques = this.replaceForbiddenChars(newVal);
    },
    'dialog.history': {
      handler() {
        this.$nextTick(() => {
          const scrollContainer = this.$refs.scrollContainer;
          if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        });
      },
      deep: true
    }
  },
  mounted() {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.isSpeechRecognitionSupported = !!SpeechRecognition && !isSafariBrowser;
  },
  methods: {
    startVoiceRecognition() {
      // AIの回答中や送信中は音声認識を開始しない
      if (this.dialog.ai_answer_end || this.dialog.isLoading) return;

      if (this.isRecognizing) {
        // 音声認識を停止
        this.recognition.stop();
        this.isRecognizing = false;
        return;
      }

      // ブラウザがWeb Speech APIをサポートしているか確認
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        alert('このブラウザは音声認識をサポートしていません。');
        return;
      }

      // 音声認識の初期化
      this.recognition = new SpeechRecognition();
      this.recognition.lang = 'ja-JP'; // 日本語に設定
      this.recognition.continuous = true;
      this.recognition.interimResults = true; // 途中結果を取得

      // 音声認識のイベントハンドラ
      this.recognition.onstart = () => {
        this.isRecognizing = true;
        this.finalTranscript = ''; // 確定したテキストをリセット
      };

      this.recognition.onresult = (event) => {
        // クリア中または送信中またはAI回答中であれば処理をスキップ
        if (this.isClearing || this.dialog.ai_answer_end || this.dialog.isLoading) return;

        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const result = event.results[i];
          let transcript = result[0].transcript;

          // 置換処理を実行
          transcript = this.applyReplacements(transcript);

          // 「送信します」が含まれるかチェック
          if (transcript.includes('送信します')) {
            // 「送信します」をテキストから除外
            transcript = transcript.replace(/送信します/g, '').trim();

            if (result.isFinal) {
              // 確定したテキストに追加
              this.finalTranscript += transcript;
            } else {
              interimTranscript += transcript;
            }

            // テキストエリアを更新
            this.dialog.current_ques = this.finalTranscript + interimTranscript;

            // 音声認識を停止
            this.recognition.stop();
            this.isRecognizing = false;

            // 送信処理を実行
            this.callAdviceGpt();

            // イベントハンドラから抜ける
            return;
          }

          if (result.isFinal) {
            this.finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        // テキストエリアに確定結果と途中結果を合わせて表示
        this.dialog.current_ques = this.finalTranscript + interimTranscript;
      };


      this.recognition.onend = () => {
        this.isRecognizing = false;
      };

      this.recognition.onerror = (event) => {
        console.error('音声認識エラー:', event.error);
        this.isRecognizing = false;
      };

      // 音声認識を開始
      this.recognition.start();
    },
    // 置換処理を行うメソッド
    applyReplacements(text) {
      this.replacementPatterns.forEach(({ pattern, replacement }) => {
        text = text.replace(pattern, replacement);
      });
      return text;
    },
    // クリアボタンのメソッド
    clearInput() {
      // クリア中であることを示すフラグを設定
      this.isClearing = true;

      // 音声認識が実行中の場合は停止
      if (this.isRecognizing && this.recognition) {
        this.recognition.stop();
        this.isRecognizing = false;
      }

      // テキストエリアと確定テキストをクリア
      this.dialog.current_ques = '';
      this.finalTranscript = '';

      // イベントハンドラを削除
      if (this.recognition) {
        this.recognition.onresult = null;
        this.recognition.onend = null;
        this.recognition.onerror = null;
      }

      // フラグをリセット
      this.isClearing = false;
    },
    // 送信ボタンのメソッド
    callAdviceGpt() {
      const startTime = Date.now();
      this.dialog.isLoading = true;
      this.dialog.history.push(
        {"user_question": this.dialog.current_ques}
      );
      this.$store.dispatch("callVxActAdviceGpt", {
        history: this.dialog.history,
        mode: this.dialog.mode
      })
        .then(async (chatGenerator) => {
          if(this.dialog.history.length && !("ai_answer" in this.dialog.history[this.dialog.history.length-1])) {
            this.dialog.history[this.dialog.history.length-1]["ai_answer"]="";
            for await (const message of chatGenerator) {
              if(this.dialog.isLoading){
                this.dialog.current_ques = "";
                this.dialog.ai_answer_end = true;
                this.dialog.isLoading = false;
                this.dialog.label = "次の質問はAIの回答終了までお待ちください…";
              }
              const lastIndex = this.dialog.history.length - 1;
              const lastItem = this.dialog.history[lastIndex];
              if(lastItem && "ai_answer" in lastItem) {
                this.dialog.history[lastIndex] = {
                  ...lastItem,
                  ai_answer: (lastItem.ai_answer || '') + message.replace(/\n/g, '<br>')
                };
              } else {
                console.log("ポップアップ閉じて終了");
                break;
              }
            }
          } else {
            console.log("ポップアップ閉じて終了");
          }

          const endTime = Date.now();
          const elapsedTimeInSeconds = (endTime - startTime) / 1000;
          console.log(`処理が完了するまでに ${elapsedTimeInSeconds} 秒かかりました`);

          // 音声認識情報をクリア
          this.clearInput();
          this.dialog.ai_answer_end = false;
          this.dialog.label = "AIへの質問";

          // 音声認識を再開
          this.startVoiceRecognition();
        })
        .catch((error) => {
          console.error(error);
          alert("エラーが発生しました。もう一度お試しください。");
          this.dialog.isLoading = false;

          // 音声認識を再開
          this.startVoiceRecognition();
        });
    },
    openPopup() {
      this.dialog.visible = true;
      this.dialog.mode = 0;
    },
    closePopup() {
      this.initDialog();
      this.dialog.visible = false;
    },
    trimAfterSubstring(inputString, substring, afterString) {
      const index = inputString.indexOf(substring);
      if (index !== -1) {
        return inputString.substring(0, index) + afterString;
      }
      return inputString;
    },
    initDialog() {
      this.dialog.mode = 0;
      this.dialog.isLoading = false;
      this.dialog.ai_answer_end = false;
      this.dialog.label = "AIへの質問";
      this.dialog.history = [];
      this.dialog.current_ques = "";
    },
    errorHandler(mess) {
      this.dialog.error = mess;
    },
    helpList1Chk(hint, careLevel) {
      if(hint !== ""){
        this.dialog.error_mess = this.trimAfterSubstring(hint, "に未入力","の必須項目が未入力なので質問できません");
      }else if(hint === "" && careLevel === 1){
        this.dialog.error_mess = this.not_applicable_txt;
      }else if(hint === "" || this.not_applicable_txt === this.dialog.error_mess){
        this.dialog.error_mess = "";
      }
    },
    replaceForbiddenChars(value) {
      let result = value?value.replace(/;/g, '；').replace(/=/g, '＝').replace(/&/g, '＆').replace(/</g, '＜').replace(/>/g, '＞').replace(/"/g, '”').replace(/'/g, '’'):''
      return result;
    },
  }
};
</script>

<!-- ------CSS------ -->

<style lang="scss" scoped>
:deep(#outer_ai_ques_field) {
  .v-input__details {
    display: none;
  }
}
.v-list-item--disabled {
  min-height: auto;
  color: #999;
  margin-bottom:0 !important;
  padding-bottom:0 !important;
  div{
    margin-bottom:0 !important;
    padding-bottom:0 !important;
  }
}
#hint_error {
  margin-top:0 !important;
  padding-top:0 !important;
  margin-bottom: 1rem !important;
  opacity: 1 !important;
  color: #f00;
  margin-left: 1.8rem;
  line-height: 1;
}
header.bg-primary {
  background-color: #00aee8!important;;
}
div#loading_icon {
    position: absolute;
    z-index: 9;
    left: 40%;
    top: 40%;
}
div#chat_caution {
  position: absolute;
  font-size: 0.8rem;
  padding-left: 0.4rem;
  bottom:0.4rem;
  line-height: 1;
}
div#chat_caution a{
  font-size: 0.8rem;
  font-weight: bold;
}
.v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-radius:0;
}
header.v-sheet.v-toolbar:not(.v-sheet--variant) {
  box-shadow: 0px 2px 4px -1px #ccc ;
}
:deep(div.v-overlay__content){
  top:0;
  left:0;
  width:100%;
  height:100%;
}
div#help_text_area {
  font-size: clamp(1.1rem, calc(0.25vw + 1.2rem), 1.6rem);
  line-height: 1.4;
  margin:0.5rem 0.5rem 0.5rem 0;
  padding:0.2rem 0.2rem 0.2rem 0;
  display:flex;
}
div#help_list {
  background: #f5f8f9;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
    color: #3c9dd8;
  }
}
#chat_help_wrap {
  display: inline;
  bottom: 0;
  position: fixed;
  z-index: 9;
}
div#advice_gpt_window {
  position: absolute;
  z-index: 3;
  top: 1%;
  left: 2%;
  width: 96%;
  height: auto;
  border: 3px solid #00aee8;
  border-radius: 0.4rem;
}
:deep(div.v-card){
  button#back_btn {
    height: auto;
    width: auto;
    margin: 0.5rem 0 0 0.5rem;
    padding: 0 0.8rem 0 0.5rem;
    background-color: #f5f8fa;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 4px -1px #ccc;
    border-radius: 10%;
  }
  .ai_answer_ico{
    padding: 4px 10px 5px 10px;
    background-color: rgba(196, 241, 195, 0.29);
    margin-bottom: 0.5rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    display: inline-block;
  }
  #ai_ques_area{
    padding:1rem;
    max-height: 50vh;
    background: linear-gradient(to bottom, #f7fafb, #e1e5f4);
    overflow: auto;
    border-top: #ccc 1px solid;
    border-left: #ccc 1px solid;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    0 0 0 2px rgb(255, 255, 255),
    0.1em 0.1em 0.3em rgba(0, 0, 0, 0.2);
    border-radius: 0.2rem;
  }
  .ai_ques_field_old{
    text-shadow:1px 1px 2px #fff;
    background: linear-gradient(to bottom, #fff, #e1e5f4);
    border-bottom: #ccc 1px solid;
    margin-bottom:1rem;
    white-space: inherit;
    b{
      text-shadow:1px 1px 1px #666;
      box-shadow:0px 1px 2px #ccc;
      background: #00aee8;
      border:#fff 2px solid;
      margin-right:0.3rem;
      color:#fff;
    }
  }
  label.v-label.theme--light{
    font-size: 1.3rem  !important;
    font-weight:bold;
    color: #aaa;
  }
}

:deep(.v-textarea.v-text-field .v-field__field ){
  margin-right: 0
  label {
    font-size: 1.8rem !important;
    font-weight:bold;
  }
}
:deep(.v-label.v-label--active){
  font-size: 1.2rem  !important;
  font-weight:bold;
  line-height: 1 !important;
  padding: 4px 10px 5px 10px;
  background-color: #00aee8;
  color:#fff !important;
  border-radius: 6px;
  border: 1px solid #ccc;
  top:10px !important;
}

:deep(div.v-text-field__slot label ){
  height: auto;
  line-height: 1;
}
:deep(#ai_ques_field){
  font-size: 1.4rem !important;
  padding: 16px 66px 16px 10px;
  overflow: auto;
  max-height: 10vh;
  border-radius: 6px;
  margin-bottom: 10px;
}
h3.d-flex.justify-space-between{
      font-size: 1.1rem;
}
span.error_area {
  color:#5f0606;
  background-color: #f0cbcc;
  padding:0.5rem;
}
#ai_ans_area {
  :deep(b) {
    margin-right: 0.2rem;
    padding: 0.2rem 0.3rem !important;
  }
  :deep(h1),
  :deep(h2),
  :deep(h3),
  :deep(h4),
  :deep(h5),
  :deep(h6) {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    font-size: 1.2rem !important;
    margin-right: 0.2rem;
    padding: 0.2rem 0.3rem !important;
    margin-bottom: 0 !important;
  }
}
div.sp_mode{
  button#advice_gpt_btn {
    :deep(span.v-btn__content){
      width:100px;
      margin-left: 18px;
    }
  }
  #advice_gpt_window{
    left: 1% !important;
    width: 98% !important;
    h3{
      font-size: 0.9rem !important;
    }
    .v-card-text{
      margin-top: 0 !important;
      padding:6px 8px 6px 6px!important;
    }
    #help_list{
      .v-list-item {
        min-height: 0 !important;
      }
      #help_list1,
      #help_list2{
        margin:0 !important;
      }
    }
    .ai_ques_field_old{
      padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
      margin-bottom: 0.5rem !important;
      :deep(b ){
        font-size: 0.8rem !important;
        margin-right: 0.2rem;
        padding: 0.2rem 0.3rem !important;
      }
    }
    .ai_ans_field_old{
      :deep(b ){
        font-size: 0.8rem !important;
        margin-right: 0.2rem;
        padding: 0.2rem 0.3rem !important;
      }
    }
    h3.d-flex.justify-space-between{
      font-size: 0.9rem;
      margin-bottom: 8px !important;
      button#back_btn{
        margin: 0.5rem 0 0 0.3rem;
        padding: 0 0.4rem 0 0.2rem;
        :deep(span.v-btn__content ){
          font-size: 0.8rem !important;
          i {
            font-size: 1rem !important;
            height: 12px;
            width: 12px;
          }
        }
      }
    }
    #outer_ai_ques_field{
      div.v-col{
        padding: 0;
      }
      :deep(label.v-label){
        font-size:1rem !important;
      }
      :deep(textarea#ai_ques_field){
        font-size:0.9rem !important;
        padding: 0.8rem 3rem 0 0.5rem !important;
      }
      #submit_btn{
        padding: 0;
        top: 0 !important;
        right: 0.6rem !important;
        min-width: 38px !important;
        height: 32px !important;
        :deep(span ){
          font-size: 0.8rem !important;
        }
      }
    }
    #ai_ques_area{
      padding:0.5rem;
    }
  }
}
</style>