import _ from 'lodash';

// UIの種別ごとに、値が入っているかどうかを検証する関数を_.inBlankとして定義
_.mixin({
  inBlank: function (items, options) {
    let _items = options && options.required ? _.filter(items, x => x.required === true) : items;
    return _.filter(_items, x => {
      switch (x.type) {
        case "chip-group":
          if (x.options.multiple) {
            return _.isNil(x.value) || _.isEmpty(x.value);
          } else {
            return _.isNil(x.value) || (x.key === 'sex' && x.value === 0);
          }
        case "ew-chip-group":
          return _.isNil(x.value) || _.isEmpty(x.value);
        case "date-date":
          return _.isNil(x.value) /* || _.isNil(x.value[0]) || _.isNil(x.value[1]) */;
        case "text-field":
          return _.isNil(x.value) || _.isEmpty(x.value);
        case "ew-text-date":
          return (
            _.isNil(x.value[0]) ||
            _.isEmpty(x.value[0]) ||
            _.isNil(x.value[1]) ||
            _.isEmpty(x.value[1])
          );
        case "ew-textarea":
          return _.isNil(x.value) || _.isEmpty(x.value);
        case "textarea":
          return _.isNil(x.value) || _.isEmpty(x.value);
        default:
          return _.isNil(x.value);
      }
    });
  }
});

// Vue 3用にプラグイン登録方法を更新
const VueLodash = {
  install(app) {
    app.config.globalProperties.$_ = _;
  }
};

export default VueLodash;