<template>
  <div class="steptab__item">
    <v-btn
      :id="id"
      class="steptab__circle"
      :color="color"
      :elevation="disabled ? 0 : 2"
      :variant="selected?'flat':'outlined'"
      :disabled="disabled"
      @click.stop="$emit('click')"
    >
      <slot class="steptab__content" />
    </v-btn>

    <v-badge
      v-if="checked"
      class="steptab__icon-check"
      icon="mdi-check-bold"
    />

    <v-fade-transition>
      <div class="text-center">
        <slot name="message" />
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: "StepTab",
  props: {
    id: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: "primary"
    },
    selected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'], 
};
</script>

<style lang="scss" scoped>
.chip--required {
  .v-chip__content {
    font-size: 0.7rem;
  }
}

.steptab {
  &__item {
    position: relative;
    margin: 0 7px;
  }

  &__circle {
    $circle-size: 75px;
    width: $circle-size !important;
    height: $circle-size !important;
    border-radius: 50%;
    border-width: 2px;
    overflow: hidden;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.75);
    
    transition: $transition_borderradius, $transition_height, $transition_borderwidth;

    .scrolled .steptab--sticky & {
      border-radius: 0 0 5px 5px;
      border-width: 0 2px 2px 2px;
      height: 40px !important;
    }
  }

  &__icon-check {
    position: absolute;
    top: 9px;
    right: 8px;
    
    .scrolled .steptab--sticky & {
      right: 6px;
      top: 42px;
    
      transition: $transition_top, $transition_right;
    }
  }
}
</style>
