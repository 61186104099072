import { createRouter, createWebHistory } from 'vue-router';
import Customer from '../views/Customer.vue'
import CheckList from '../views/CheckList.vue';
import CheckListEdit from '../views/CheckListEdit.vue';

import HistList from '../views/HistList.vue';
import Hist from '../views/Hist.vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    // 入居者様一覧
    {
      path: '/',
      redirect: { name: 'customer' }
    },
    {
      path: '/:patgMatch(_)?guests/customer',
      name: 'customer',
      component: Customer
    },
    {
      path: '/:patgMatch(_)?guests/:guestId/checklists/:checkListId?',
      name: 'checklist',
      component: CheckList
    },
    {
      path: '/:patgMatch(_)?guests/:guestId/checklistedit/:checkListId/:typeName(check|feedback|interview)',
      name: 'checklist-edit',
      component: CheckListEdit
    },
    // 履歴
    {
      path: '/:patgMatch(_)?guests/history',
      name: 'history',
      component: HistList
    },
    {
      path: '/:patgMatch(_)?guests/:guestId/hist',
      name: 'hist',
      component: Hist
    },
    // その他
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'customer' }
    },
  ]
});

export default router;