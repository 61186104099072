import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.locale('ja');
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

import axios from 'axios';

export default {
  async findInfos({ targetDtm }) {
    let res = await axios.get("/api/admin/v1/information", {
      params: {
        target_dtm: targetDtm
      }
    });
    return res.data;
  },
  async authenticate({ accountName, accountPassword, rememberMe }) {
    let res = await axios.post('/api/admin/v1/authenticate', {
      accountName,
      accountPassword,
      rememberMe
    });
    return res.data.token;
  },
  async findSelf({ token }) {
    let res = await axios.get("/api/admin/v1/self", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async updateUserInfo({ token, userName, userMail, aiModelDefault }) {
    // user更新
    await axios.put("/api/admin/v1/self", { userName, userMail, aiModelDefault }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    // user取得
    return await this.findSelf({ token });
  },
  async changePassword({ token, oldPassword, newPassword }) {
    // 旧パスワードのチェック
    await axios.put("/api/admin/v1/account", { oldPassword, newPassword }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return true;
  },
  async findOffices({ token }) {
    let res = await axios.get("/api/admin/v1/offices", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async createGuest({ token, officeId, insuranceId, guestLastName, guestFirstName, guestAddress, sexTypeCd, guestBirthDt }) {
    let res = await axios.post("/api/admin/v1/guests", { officeId, insuranceId, guestLastName, guestFirstName, guestAddress, sexTypeCd, guestBirthDt }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async updateGuest({ token, guestId, officeId, insuranceId, guestLastName, guestFirstName, guestAddress, sexTypeCd, guestBirthDt }) {
    let res = await axios.put(`/api/admin/v1/guests/${guestId}`, { insuranceId, officeId, guestLastName, guestFirstName, guestAddress, sexTypeCd, guestBirthDt }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async findGuests({ token }) {
    let res = await axios.get("/api/admin/v1/guests", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async deleteGuest({ token, guestId }) {
    let res = await axios.delete(`/api/admin/v1/guests/${guestId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async findSexTypes({ token }) {
    let res = await axios.get("/api/admin/v1/const/sex-types", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async createCarePlan({ token, guestId, targetDt, conditions, servicePlans }) {
    let res = await axios.post(`/api/admin/v1/guests/${guestId}/care-plans`, { targetDt, conditions: JSON.stringify(conditions), servicePlans: JSON.stringify(servicePlans) }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async findCarePlans({ token, guestId }) {
    let res = await axios.get(`/api/admin/v1/guests/${guestId}/care-plans`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async deleteCarePlan({ token, carePlanId }) {
    let res = await axios.delete(`/api/admin/v1/care-plans/${carePlanId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async findServiceItems({ token }) {
    let res = await axios.get('/api/admin/v1/services', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async saveConditions({ token, carePlanId, conditions }) {
    conditions = JSON.stringify(conditions);
    let res = await axios.put(`/api/admin/v1/care-plans/${carePlanId}/conditions`, { conditions }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async saveCarePlan({ token, carePlanId, conditions, servicePlans }) {
    conditions = JSON.stringify(conditions);
    servicePlans = JSON.stringify(servicePlans);
    let res = await axios.put(`/api/admin/v1/care-plans/${carePlanId}`, { conditions, servicePlans }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  /**
   * サーバのPDF作成API呼び出し
   * @param {} param0 
   */
  async pdfCarePlan({ token, carePlanId, printInfo, conditions, selectedConditions, data }) {
    printInfo = JSON.stringify(printInfo);
    conditions = JSON.stringify(conditions);
    selectedConditions = JSON.stringify(selectedConditions);
    data = JSON.stringify(data);
    let res = await axios.post(`/api/pdf/v1/care-plans/${carePlanId}`, { printInfo, conditions, selectedConditions, data }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },

  /**
   * AIモデルの呼び出し 改善志向プラン＆状態が最も近い方のプラン
   */
  async callRecommend2Plans({ token, query }) {
    let res = await axios.post('/api/admin/v1/soin-ai/recommend-2plans', { query }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).catch(err => {
      throw err;
    });
    return res;
  },
  // サービスプランから将来の状態を予測
  async callForecastConditions({ token, query }) {
    let res = await axios.post('/api/admin/v1/soin-ai/forecast_conditions', { query }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).catch(err => {
      throw err;
    });
    return res;
  },

  /**
   * AIモデルの一覧を取得する
   * @param token 
   */
  async findAiModels({ token }) {
    let res = await axios.post('/api/admin/v1/ai-models', {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },

  // ログ保存
  async createLog({ token, message, description }) {
    // alert("message = " + message + ",description = " + description);
    let res = await axios.post('/api/admin/v1/log', { message, description }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).catch(err => {
      throw err;
    });
    return res;
  },
 
  // 組織
  async findOrganizations({ token }) {
    let res = await axios.get("/api/admin/v1/organizations", {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async findOrganization({ token, organizationId }) {
    let res = await axios.get(`/api/admin/v1/organizations/${organizationId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async updateOrganization({ token, organizationId, organizationName, organizationAddress, canShowAllGuests, aiModelDefault, welfare }) {
    let res = await axios.put(`/api/admin/v1/organizations/${organizationId}`,
      { organizationName, organizationAddress, canShowAllGuests, aiModelDefault, welfare }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async deleteOrganization({ token, organizationId }) {
    let res = await axios.delete(`/api/admin/v1/organizations/${organizationId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async createOrganization({ token, organizationId, organizationName, organizationAddress, canShowAllGuests, aiModelDefault, welfare }) {
    let res = await axios.post("/api/admin/v1/organizations",
      { organizationId, organizationName, organizationAddress, canShowAllGuests, aiModelDefault, welfare }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },

  // 組織-AIモデル
  async findOrganizationAiModels({ token }) {
    let res = await axios.get("/api/admin/v1/organization-aimodel", {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async findOrganizationAiModel({ token, organizationAiModelId }) {
    let res = await axios.get(`/api/admin/v1/organization-aimodel/${organizationAiModelId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async updateOrganizationAiModel({ token, organizationAiModelId, organizationId, aiModelId, ordByNo }) {
    let res = await axios.put(`/api/admin/v1/organization-aimodel/${organizationAiModelId}`,
      { organizationId, aiModelId, ordByNo }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async deleteOrganizationAiModel({ token, organizationAiModelId }) {
    let res = await axios.delete(`/api/admin/v1/organization-aimodel/${organizationAiModelId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async createOrganizationAiModel({ token, organizationAiModelId, organizationId, aiModelId, ordByNo }) {
    let res = await axios.post("/api/admin/v1/organization-aimodel",
      { organizationAiModelId, organizationId, aiModelId, ordByNo }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },

  /**
   * ping/pong
   */
  async ping({ token }) {
    let res = await axios.post('/api/admin/v1/ping', {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },

  // 管理機能 ---------------------------
  /**
   * ユーザ一覧取得
   * @param {*} param0 
   * @returns 
   */
  async findUsers({ token }) {
    let res = await axios.get("/api/admin/v1/users", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  /**
   * ユーザ作成
   * @param {*} param0 
   * @returns 
   */
  async createUser({ token, userId, userName, userMail, accountName, accountPassword, beginDtm, endDtm }) {
    let res = await axios.post("/api/admin/v1/users", { userId, userName, userMail, accountName, accountPassword, beginDtm, endDtm }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  /**
   * ユーザ更新
   * @param {*} param0 
   * @returns 
   */
  async updateUser({ token, userId, userName, userMail, beginDtm, endDtm }) {
    let res = await axios.put(`/api/admin/v1/users/${userId}`, { userName, userMail, beginDtm, endDtm }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async updatePassword({ token, userId, accountPassword}) {
    let res = await axios.put(`/api/admin/v1/users/${userId}/account`, { accountPassword }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  async deleteUser({ token, userId }) {
    let res = await axios.delete(`/api/admin/v1/users/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },

  /**
   * API-オフィス所属情報を作成する
   * @param {*} param0 
   * @returns 
   */
  async createOfficeUsers({ token, officeUserId, officeId, userId, beginDtm, endDtm }) {
    let res = await axios.post("/api/admin/v1/office-users", { officeUserId, officeId, userId, beginDtm, endDtm }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  /**
   * API-オフィス所属情報を作成する
   * @param {*} param0 
   * @returns 
   */
  async findOfficeUsersByUserId({ token, userId }) {
    let res = await axios.get(`/api/admin/v1/office-users-byid/${userId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  /**
   * API-オフィス所属情報を作成する
   * @param {*} param0 
   * @returns 
   */
   async findOfficeUsers({ token }) {
    let res = await axios.get("/api/admin/v1/office-users", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  /**
   * 事業所作成
   * @param {*} param0 
   * @returns 
   */
   async createOffice({ token, officeId, officeName, officeAddress, areaId }) {
    let res = await axios.post("/api/admin/v1/offices", { officeId, officeName, officeAddress, areaId }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  /**
   * ユーザ更新
   * @param {*} param0 
   * @returns 
   */
  async updateOffice({ token, officeId, officeName, officeAddress, ordByNo }) {
    let res = await axios.put(`/api/admin/v1/offices/${officeId}`, { officeName, officeAddress, ordByNo }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  /**
   * 事業所削除
   * @param {*} param0 
   * @returns 
   */
   async deleteOffice({ token, officeId }) {
    let res = await axios.delete(`/api/admin/v1/offices/${officeId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  /**
   * 事業所-ユーザ削除
   * @param {*} param0 
   * @returns 
   */
   async deleteOfficeUser({ token, officeUserId }) {
    let res = await axios.delete(`/api/admin/v1/office-users/${officeUserId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  /**
   * お知らせ作成
   * @param {*} param0 
   * @returns 
   */
   async createInfo({ token, informationText, beginDtm, endDtm }) {
    let res = await axios.post("/api/admin/v1/information", { informationText, beginDtm, endDtm }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return res.data;
  },
  /**
   * ユーザ作成
   * @param {*} param0 
   * @returns 
   */
   async updateInfo({ token, informationId, informationText, beginDtm, endDtm }) {
    let res = await axios.put(`/api/admin/v1/information/${informationId}`, { informationText, beginDtm, endDtm }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  /**
   * お知らせ削除
   * @param {*} param0 
   * @returns 
   */
  async deleteInfo({ token, informationId }) {
    let res = await axios.delete(`/api/admin/v1/information/${informationId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
};