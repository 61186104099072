import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vuelodash from './plugins/lodash'
//import vuejsdialog from 'vuejs-dialog'
import Vue3ConfirmDialog from 'vue3-confirm-dialog'
import 'vue3-confirm-dialog/style'
import VTooltip from "v-tooltip"
import { loadFonts } from './plugins/webfontloader'
// 環境変数によりbuid時にdebugの有効／無効を決定
if (process.env.VUE_APP_MODE === 'development') {
  require("debug").enable("soin-client:*");
} else if (process.env.VUE_APP_MODE === 'production') {
  require("debug").disable();
}

loadFonts()

const app = createApp(App);

//$vuetify.goToの代替処理。$vuetify.goToと記載された部分を$goToとすれば利用できる。
app.config.globalProperties.$goTo = function (target, options = {}) {
  const defaults = {
    duration: 300, // デフォルトの持続時間
    offset: 0,     // オフセット
    easing: 'ease-in-out', // デフォルトのイージング
    ...options    // オプションをマージ
  };

  const getTargetPosition = (target) => {
    if (typeof target === 'number') {
      return target;
    }
    const element = document.querySelector(target);
    if (element) {
      return element.getBoundingClientRect().top + window.pageYOffset + defaults.offset;
    }
    return 0;
  };

  const position = getTargetPosition(target);
  window.scrollTo({
    top: position,
    behavior: defaults.duration === 0 ? 'auto' : 'smooth'
  });
};
//$vuetify.goToの代替処理ここまで

app.use(vuelodash)
  .use(Vue3ConfirmDialog)
  .use(VTooltip)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');

app.component('Vue3ConfirmDialog', Vue3ConfirmDialog.default); // グローバルコンポーネントとして登録
