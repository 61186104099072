<template>
  <div class="home pa-2">
    <v-container
      class="pa-0"
      fluid
    >
      <v-row no-gutters>
        <v-col class="pa-0 py-2">
          <h2 id="home-page">
            <v-icon>mdi-history</v-icon> {{ title }}一覧
          </h2>
        </v-col>
        <v-col
          id="guests_head"
          class="pa-0 py-2"
          cols="12"
          md="5"
        >
          <v-row
            no-gutters
            justify="end"
          >
            <v-col class="pr-4">
              <v-text-field
                id="home-search-text"
                v-model="searchText"
                type="search"
                :label="`${title}を検索`"
                density="compact"
                prepend-inner-icon="mdi-magnify"
                clearable
              />
              <v-tooltip
                activator="parent"
                location="top"
              >
                入力した文字を含む{{ title }}を絞り込み表示します
              </v-tooltip>
            </v-col>
            <v-col
              cols="1"
              class="float-right"
              style="text-align: right;"
            >
              <v-btn
                id="home-refresh"
                width="100%"
                :loading="isLoading"
                :disabled="isLoading"
                @click="updateGuests"
              >
                <v-icon color="gray">
                  mdi-reload
                </v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  最新情報に更新
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet elevation="5">
      <v-data-table
        v-if="!isLoading"
        id="home-user-list"
        :headers="headers"
        :items="customData"
        item-value="guestId"
        :loading="isLoading"
        :search="searchText"
        :loading-text="`${title}一覧を取得中...`"
        :no-results-text="`該当する${title}が存在しません。`"
        items-per-page-text="表示行数"
        class="home__datatable"
        @click:row="selectGuest"
      >
        <template #no-data>
          登録された{{ title }}は存在しません。
        </template>
        <template #[`item.judge_2`]="{item}">
          <div v-if="2 <= item.careLevel_2 && item.careLevel_2 <= 8">
            <v-icon color="black">
              mdi-account-alert
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              {{ [`要支援1`, `要支援2`, `要介護1`, `要介護2`, `要介護3`, `要介護4`,`要介護5`][item.careLevel_2 - 2] }}
            </v-tooltip>
          </div>
          <div v-else-if="item.judge_2 === 1">
            <v-icon color="black">
              mdi-inbox-remove
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              未提出
            </v-tooltip>
          </div>
          <div v-else-if="item.judge_2 === 2">
            <v-icon color="red">
              mdi-comment-remove-outline
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              不備あり
            </v-tooltip>
          </div>
          <template v-else-if="item.judge_2 >= 3">
            <a>
              <v-icon :color="(item.judge01_20_2 === 2) ? 'red' : (item.judge01_20_2 === 1) ? 'gray' : 'orange'">
                {{ (item.judge01_20_2 === 2) ? 'mdi-hours-24' : (item.judge01_20_2 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ① 全般的な生活機能低下の恐れ {{ (item.judge01_20_2 === 2) ? '該当' : (item.judge01_20_2 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge06_10_2 === 2) ? 'red' : (item.judge06_10_2 === 1) ? 'gray' : 'orange'">
                {{ (item.judge06_10_2 === 2) ? 'mdi-yoga' : (item.judge06_10_2 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ② 運動機能低下の恐れ {{ (item.judge06_10_2 === 2) ? '該当' : (item.judge06_10_2 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge11_12_2 === 2) ? 'red' : (item.judge11_12_2 === 1) ? 'gray' : 'orange'">
                {{ (item.judge11_12_2 === 2) ? 'mdi-noodles' : (item.judge11_12_2 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ③ 低栄養の恐れ {{ (item.judge11_12_2 === 2) ? '該当' : (item.judge11_12_2 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge13_15_2 === 2) ? 'red' : (item.judge13_15_2 === 1) ? 'gray' : 'orange'">
                {{ (item.judge13_15_2 === 2) ? 'mdi-tooth' : (item.judge13_15_2 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ④ 口腔機能低下の恐れ {{ (item.judge13_15_2 === 2) ? '該当' : (item.judge13_15_2 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge16_17_2 === 2) ? 'red' : (item.judge16_17_2 === 1) ? 'gray' : 'orange'">
                {{ (item.judge16_17_2 === 2) ? 'mdi-home-account' : (item.judge16_17_2 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ⑤ 閉じこもりの恐れ {{ (item.judge16_17_2 === 2) ? '該当' : (item.judge16_17_2 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge18_20_2 === 2) ? 'red' : (item.judge18_20_2 === 1) ? 'gray' : 'orange'">
                {{ (item.judge18_20_2 === 2) ? 'mdi-head-question' : (item.judge18_20_2 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ⑥ 認知機能低下の恐れ {{ (item.judge18_20_2 === 2) ? '該当' : (item.judge18_20_2 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge21_25_2 === 2) ? 'red' : (item.judge21_25_2 === 1) ? 'gray' : 'orange'">
                {{ (item.judge21_25_2 === 2) ? 'mdi-heart-minus' : (item.judge21_25_2 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ⑦ うつ傾向の可能性あり {{ (item.judge21_25_2 === 2) ? '該当' : (item.judge21_25_2 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
          </template>
        </template>
        <template #[`item.judge_1`]="{item}">
          <div v-if="2 <= item.careLevel_1 && item.careLevel_1 <= 8">
            <v-icon color="black">
              mdi-account-alert
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              {{ [`要支援1`, `要支援2`, `要介護1`, `要介護2`, `要介護3`, `要介護4`,`要介護5`][item.careLevel_1 - 2] }}
            </v-tooltip>
          </div>
          <div v-else-if="item.judge_1 === 1">
            <v-icon color="black">
              mdi-inbox-remove
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              未提出
            </v-tooltip>
          </div>
          <div v-else-if="item.judge_1 === 2">
            <v-icon color="red">
              mdi-comment-remove-outline
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              不備あり
            </v-tooltip>
          </div>
          <template v-else-if="item.judge_1 >= 3">
            <a>
              <v-icon :color="(item.judge01_20_1 === 2) ? 'red' : (item.judge01_20_1 === 1) ? 'gray' : 'orange'">
                {{ (item.judge01_20_1 === 2) ? 'mdi-hours-24' : (item.judge01_20_1 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ① 全般的な生活機能低下の恐れ {{ (item.judge01_20_1 === 2) ? '該当' : (item.judge01_20_1 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge06_10_1 === 2) ? 'red' : (item.judge06_10_1 === 1) ? 'gray' : 'orange'">
                {{ (item.judge06_10_1 === 2) ? 'mdi-yoga' : (item.judge06_10_1 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ② 運動機能低下の恐れ {{ (item.judge06_10_1 === 2) ? '該当' : (item.judge06_10_1 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge11_12_1 === 2) ? 'red' : (item.judge11_12_1 === 1) ? 'gray' : 'orange'">
                {{ (item.judge11_12_1 === 2) ? 'mdi-noodles' : (item.judge11_12_1 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ③ 低栄養の恐れ {{ (item.judge11_12_1 === 2) ? '該当' : (item.judge11_12_1 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge13_15_1 === 2) ? 'red' : (item.judge13_15_1 === 1) ? 'gray' : 'orange'">
                {{ (item.judge13_15_1 === 2) ? 'mdi-tooth' : (item.judge13_15_1 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ④ 口腔機能低下の恐れ {{ (item.judge13_15_1 === 2) ? '該当' : (item.judge13_15_1 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge16_17_1 === 2) ? 'red' : (item.judge16_17_1 === 1) ? 'gray' : 'orange'">
                {{ (item.judge16_17_1 === 2) ? 'mdi-home-account' : (item.judge16_17_1 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ⑤ 閉じこもりの恐れ {{ (item.judge16_17_1 === 2) ? '該当' : (item.judge16_17_1 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge18_20_1 === 2) ? 'red' : (item.judge18_20_1 === 1) ? 'gray' : 'orange'">
                {{ (item.judge18_20_1 === 2) ? 'mdi-head-question' : (item.judge18_20_1 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ⑥ 認知機能低下の恐れ {{ (item.judge18_20_1 === 2) ? '該当' : (item.judge18_20_1 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge21_25_1 === 2) ? 'red' : (item.judge21_25_1 === 1) ? 'gray' : 'orange'">
                {{ (item.judge21_25_1 === 2) ? 'mdi-heart-minus' : (item.judge21_25_1 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ⑦ うつ傾向の可能性あり {{ (item.judge21_25_1 === 2) ? '該当' : (item.judge21_25_1 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
          </template>
        </template>
        <template #[`item.judge`]="{item}">
          <div v-if="2 <= item.careLevel && item.careLevel <= 8">
            <v-icon color="black">
              mdi-account-alert
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              {{ [`要支援1`, `要支援2`, `要介護1`, `要介護2`, `要介護3`, `要介護4`,`要介護5`][item.careLevel - 2] }}
            </v-tooltip>
          </div>
          <div v-else-if="item.judge === 1">
            <v-icon color="black">
              mdi-inbox-remove
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              未提出
            </v-tooltip>
          </div>
          <div v-else-if="item.judge === 2">
            <v-icon color="red">
              mdi-comment-remove-outline
            </v-icon>
            <v-tooltip
              activator="parent"
              location="top"
            >
              不備あり
            </v-tooltip>
          </div>
          <template v-else-if="item.judge >= 3">
            <a>
              <v-icon :color="(item.judge01_20 === 2) ? 'red' : (item.judge01_20 === 1) ? 'gray' : 'orange'">
                {{ (item.judge01_20 === 2) ? 'mdi-hours-24' : (item.judge01_20 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ① 全般的な生活機能低下の恐れ {{ (item.judge01_20 === 2) ? '該当' : (item.judge01_20 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge06_10 === 2) ? 'red' : (item.judge06_10 === 1) ? 'gray' : 'orange'">
                {{ (item.judge06_10 === 2) ? 'mdi-yoga' : (item.judge06_10 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ② 運動機能低下の恐れ {{ (item.judge06_10 === 2) ? '該当' : (item.judge06_10 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge11_12 === 2) ? 'red' : (item.judge11_12 === 1) ? 'gray' : 'orange'">
                {{ (item.judge11_12 === 2) ? 'mdi-noodles' : (item.judge11_12 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ③ 低栄養の恐れ {{ (item.judge11_12 === 2) ? '該当' : (item.judge11_12 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge13_15 === 2) ? 'red' : (item.judge13_15 === 1) ? 'gray' : 'orange'">
                {{ (item.judge13_15 === 2) ? 'mdi-tooth' : (item.judge13_15 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ④ 口腔機能低下の恐れ {{ (item.judge13_15 === 2) ? '該当' : (item.judge13_15 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge16_17 === 2) ? 'red' : (item.judge16_17 === 1) ? 'gray' : 'orange'">
                {{ (item.judge16_17 === 2) ? 'mdi-home-account' : (item.judge16_17 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ⑤ 閉じこもりの恐れ {{ (item.judge16_17 === 2) ? '該当' : (item.judge16_17 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge18_20 === 2) ? 'red' : (item.judge18_20 === 1) ? 'gray' : 'orange'">
                {{ (item.judge18_20 === 2) ? 'mdi-head-question' : (item.judge18_20 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ⑥ 認知機能低下の恐れ {{ (item.judge18_20 === 2) ? '該当' : (item.judge18_20 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
            <a>
              <v-icon :color="(item.judge21_25 === 2) ? 'red' : (item.judge21_25 === 1) ? 'gray' : 'orange'">
                {{ (item.judge21_25 === 2) ? 'mdi-heart-minus' : (item.judge21_25 === 1) ? 'mdi-check' : 'mdi-comment-question-outline' }}
              </v-icon>
              <v-tooltip
                activator="parent"
                location="top"
              >
                ⑦ うつ傾向の可能性あり {{ (item.judge21_25 === 2) ? '該当' : (item.judge21_25 === 1) ? '非該当' : '未入力あり' }}
              </v-tooltip>
            </a>
          </template>
        </template>
        <template #[`no-data`]>
          登録された{{ title }}は存在しません。
        </template>
      </v-data-table>
    </v-sheet>
    <!-- AIモデル実行時に表示するダイアログ -->
    <v-dialog
      :model-value="isLoading"
      persistent
      width="300px"
    >
      <v-card
        color="info"
        dark
      >
        <v-card-text>
          <span>履歴情報を読み込み中・・・</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @namespace app.histlist
 */
import {mapGetters} from "vuex";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale(config.locale);
import config from "../config";

/**
 * ビューモデル
 * @class
 * @memberof app.histlist
 */
export default {
  name: "HistList",
  data() {
    return {
      title: '履歴',
      headers: [
        {
          title: "事業所名",
          value: "officeName",
          class: [],
          sortable: true,
        },
        {
          title: "被保険者ID",
          value: "insuranceId",
          class: [],
          sortable: true,
        },
        {
          title: "利用者名",
          value: "name",
          class: [],
          sortable: true,
        },
        {
          title: Number(dayjs().subtract(2, 'year').format('YYYY')) + "年",
          value: "judge_2",
          class: [],
          align: "center",
          sortable: true,
        },
        {
          title: Number(dayjs().subtract(1, 'year').format('YYYY')) + "年",
          value: "judge_1",
          class: [],
          align: "center",
          sortable: true,
        },
        {
          title: Number(dayjs().format('YYYY')) + "年",
          value: "judge",
          class: [],
          align: "center",
          sortable: true,
        },
      ],
      isLoading: false,
      searchText: null,
      pageOptions: null,
    };
  },
  computed: {
    ...mapGetters([
      "activeUser",
      "activeHists",
    ]),
    customActiveHists() {
      return this.activeHists || [];
    },
    /**
     * 入居入居者様一覧の加工
     * @returns {Array} 入居者様一覧
     */
    customData() {
      return _(this.customActiveHists || [])
        .filter(x => (x.careLevel[0] <= 1 && x.careLevel[1] <= 1 && x.careLevel[2] <= 1)) // 自立者のみ
        .map(x => ({
          ..._.pick(x, [
            "guestId",
            "insuranceId",
          ]),
          name: `${x.lastName} ${x.firstName}`,
          updDtm: dayjs(x.updDtm).toISOString().substr(0, 10), // dayjs(x.updDtm).format("YYYY.MM.DD"),
          officeId: x.offices.length > 0 ? _.head(x.offices).officeId : null,
          officeName: x.offices.length > 0 ? _.head(x.offices).officeName : "",
          targetDtm: x.targetDtm,
          careLevel: x.careLevel[0], //x.careLevel, // 自立者：空白 / 自立者以外：入力された要介護状態区分を表示する。
          judge: x.judge[0], // x.judge,
          judge01_20: x.judge01_20[0],
          judge06_10: x.judge06_10[0],
          judge11_12: x.judge11_12[0],
          judge13_15: x.judge13_15[0],
          judge16_17: x.judge16_17[0],
          judge18_20: x.judge18_20[0],
          judge21_25: x.judge21_25[0],
          careLevel_1: x.careLevel[1], //x.careLevel, // 自立者：空白 / 自立者以外：入力された要介護状態区分を表示する。
          judge_1: x.judge[1], // x.judge,
          judge01_20_1: x.judge01_20[1],
          judge06_10_1: x.judge06_10[1],
          judge11_12_1: x.judge11_12[1],
          judge13_15_1: x.judge13_15[1],
          judge16_17_1: x.judge16_17[1],
          judge18_20_1: x.judge18_20[1],
          judge21_25_1: x.judge21_25[1],
          careLevel_2: x.careLevel[2], //x.careLevel, // 自立者：空白 / 自立者以外：入力された要介護状態区分を表示する。
          judge_2: x.judge[2], // x.judge,
          judge01_20_2: x.judge01_20[2],
          judge06_10_2: x.judge06_10[2],
          judge11_12_2: x.judge11_12[2],
          judge13_15_2: x.judge13_15[2],
          judge16_17_2: x.judge16_17[2],
          judge18_20_2: x.judge18_20[2],
          judge21_25_2: x.judge21_25[2],
        }))
        .value();
    },
  },
  /**
   * vueマウント時の処理
   */
  async mounted() {
    await this.updateGuests();
  },
  methods: {
    /**
     * 履歴情報の更新
     */
    async updateGuests() {
      this.isLoading = true;
      await this.$store.dispatch("updateHists");
      this.isLoading = false;
    },
    /**
     * 入居者様の選択
     * @param {Object} item 入居者様情報
     * @param {Object} row 行情報
     */
    async selectGuest(item, row) {
      // alert(JSON.stringify(item));
      let pathPrefix = (this.$route.path.substring(0, 9) == '/_guests/') ? '_guests' : 'guests';

      try {
        item = row.item;
        await this.$router
          .push(`/${pathPrefix}/${item.guestId}/hist`)
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error("ナビゲーションエラー:", error);
          });
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style scoped lang="scss">
#cancel-btn-area,
#submit-btn-area {
  button {
    padding: 0 50%;
  }
}
:deep(#submit-btn-area ){
  background-color: #00aee8 !important;
  button.text-primary span.v-btn__content{
    color:#fff!important;
  }
}
.home {
  &__datatable {
    background: transparent !important;
  }
  #home-create-btn i.v-icon--size-default,
  #home-life-btn i.v-icon--size-default{
    font-size: 18px !important; 
  }
  #home-life-btn {
    background-color: #00aee8 !important;
  }
  #guest-action{
    button {
      padding: 0;
    }
  }
  h2#home-page i.v-icon--size-default {
    font-size: 24px !important;
  }
}
@media (min-width: 960px) {
  .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
:deep(#guests_head ){
  .v-row + .v-row {
    margin-top: 0 !important;
  }
  div.v-row > div.v-col {
    padding-top: 0 !important;
  }
 .v-input__details {
    padding: 0 !important;
    min-height: 0;
  }
}
:deep(#table_upper ){
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
  div.v-col {
    padding-bottom: 0 !important;
    padding-left: 4px !important;
    margin-bottom: 26px;
    padding-top: 0;
   i.mdi-checkbox-marked{
      color: #00aee8 !important;
    }
  }
  .right {
    text-align: right;
  }
  button#home-refresh {
    margin-top: 16px;
    min-width: auto !important;
    max-width: 3rem;
  }
}
:deep(#home-user-list table ){
  td,
  th {
    padding: 0 10px !important;
    font-size: 0.875rem !important;
  }
  th {
    background-color: #eee;
    font-weight: bold;
    color: #000;
    span {
      font-size: 0.8rem !important;
    }
  }
}
:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td ){
  div > div > button {
    padding: 0;
  }
}
</style>