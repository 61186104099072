export default {
  check001: `家族等の付き添いなしで、１人でバスや電車を利用して外出しているかどうかを尋ねています。
バスや電車のないところでは、それに準じた公共機関に置き換えて回答して下さい。
なお、１人で自家用車を運転して外出している場合も含まれます。`,
  check002: `自ら外出し、何らかの日用品の買い物を適切に行っているかどうか（例えば、必要な物品を購入しているか）を尋ねています。
頻度は、本人の判断に基づき回答してください。
電話での注文のみで済ませている場合は「いいえ」となります。`,
  check003: `自ら預貯金の出し入れをしているかどうかを尋ねています。
銀行等での窓口手続きも含め、本人の判断により金銭管理を行っている場合に「はい」とします。
家族等に依頼して、預貯金の出し入れをしている場合は「いいえ」となります。`,
  check004: `友人の家を訪ねているかどうかを尋ねています。
電話による交流や家族・親戚の家への訪問は含みません。`,
  check005: `家族や友人の相談にのっているかどうかを尋ねています。
面談せずに電話のみで相談に応じている場合も「はい」とします。`,
  check006: `階段を手すりや壁をつたわらずに昇っているかどうかを尋ねています。
時々、手すり等を使用している程度であれば「はい」とします。
手すり等を使わずに階段を昇る能力があっても、習慣的に手すり等を使っている場合には「いいえ」となります。`,
  check007: `椅子に座った状態から何もつかまらずに立ち上がっているかどうかを尋ねています。
時々、つかまっている程度であれば「はい」とします。`,
  check008: `15 分位続けて歩いているかどうかを尋ねています。
屋内、屋外等の場所は問いません。`,
  check009: `この１年間に「転倒」の事実があるかどうかを尋ねています。`,
  check010: `現在、転倒に対する不安が大きいかどうかを、本人の主観に基づき回答してください。`,
  check011: `６ヵ月間で２～３㎏以上の体重減少があったかどうかを尋ねています。
６ヵ月以上かかって減少している場合は「いいえ」となります。`,
  check013: `半年前に比べて固いものが食べにくくなったかどうかを尋ねています。
半年以上前から固いものが食べにくく、その状態に変化が生じていない場合は「いいえ」となります。`,
  check014: `お茶や汁物等を飲む時に、むせることがあるかどうかを、本人の主観に基づき回答してください。`,
  check015: `口の中の渇きが気になるかどうかを、本人の主観に基づき回答してください。`,
  check016: `週によって外出頻度が異なる場合は、過去１ヵ月の状態を平均してください。`,
  check017: `昨年の外出回数と比べて、今年の外出回数が減少傾向にある場合は「はい」となります。`,
  check018: `本人は物忘れがあると思っていても、周りの人から指摘されることがない場合は「いいえ」となります。`,
  check019: `何らかの方法で、自ら電話番号を調べて、電話をかけているかどうかを尋ねています。
誰かに電話番号を尋ねて電話をかける場合や、誰かにダイヤルをしてもらい会話だけする場合には「いいえ」となります。`,
  check020: `今日が何月何日かわからない時があるかどうかを、本人の主観に基づき回答してください。
月と日の一方しか分からない場合には「はい」となります。`,
  check021: `ここ２週間の状況を、本人の主観に基づき回答してください。`,
  check022: `ここ２週間の状況を、本人の主観に基づき回答してください。`,
  check023: `ここ２週間の状況を、本人の主観に基づき回答してください。`,
  check024: `ここ２週間の状況を、本人の主観に基づき回答してください。`,
  check025: `ここ２週間の状況を、本人の主観に基づき回答してください。`,
  height: `身長、体重は、整数で記載してください。
体重は１カ月以内の値を、身長は過去の測定値を記載して差し支えありません。`,
  weight: `身長、体重は、整数で記載してください。
体重は１カ月以内の値を、身長は過去の測定値を記載して差し支えありません。`,
};