// 設定ファイル
export default {
  "locale": "ja",
  "info_duration": 1000 * 60 * 10,        // 10mins
  "contact_page_url": "https://www.cd-inc.co.jp/soin-contact/",
  "support_page_url": "https://www.cd-inc.co.jp/soin-support/",
  "user_page_url": "https://www.cd-inc.co.jp/soin-user-page/",

  "system_type": "soin-r",
  "system_key_color": "#1fa8ff",
  "enquete": "https://forms.gle/yd8DxrqxA3sggdYQ9",
  "request": "https://forms.gle/oLrG5um5xANXiPF99",
  "manual": "/Soin-R_Manual.pdf",
  "ai_ver": "1.0.0"
}