<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="290px"
    :open-delay="openDelay"
    :disabled="disabled"
  >
    <template #activator="{props}">
      <v-text-field
        :id="id"
        v-model="date"
        :class="{'init_date': !modelValue}"
        v-bind="props"
        clearable
        :label="label"
        hint="YYYY-MM-DD"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        :append-icon="appendIcon"
        readonly
        :rules="rules"
        :disabled="disabled"
        class="my-0"
        :style="styles"
      />
    </template>
    <v-date-picker
      v-model="dateForPicker"
      title="日付を選択してください"
      :min="min"
      :max="max"
      :disabled="disabled"
      @update:model-value="save"
    />
  </v-menu>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/ja";
import config from "../config";
dayjs.locale(config.locale);

export default {

  name: "DateInput",
  props: {
    id: {
      type: String,
      default: undefined
    },
    modelValue: {
      type: [Date, String],
      default: undefined
    },
    defaultDate: {
      type: [Date, String],
      default: '1940-01-01'
    },
    styles: {
      type: Object,
      default: undefined
    },
    preventEmitOnUnmount: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    prependIcon: {
      type: String,
      default: undefined
    },
    prependInnerIcon: {
      type: String,
      default: undefined
    },
    appendIcon: {
      type: String,
      default: undefined
    },
    min: {
      type: String,
      default: undefined
    },
    max: {
      type: String,
      default: undefined
    },
    locale: {
      type: String,
      default: undefined
    },
    dense: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    openDelay: {
      type: Number,
      default: 500
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      menu: false,
      date: null,
      dateForPicker: this.defaultDate, // 1940年1月1日をデフォルト値として設定
    };
  },
  watch: {
    modelValue: {
      handler(val) {
        val = val?val:this.dateForPicker;
        this.date = dayjs(val).format('YYYY-MM-DD');
        // 有効な日付の場合datepickerと共有する
        if (dayjs(this.date, 'YYYY-MM-DD').isValid()) {
          this.dateForPicker = dayjs(val).format('YYYY-MM-DD');
        } else {
          this.dateForPicker = dayjs('1940/1/1 00:00:00').format('YYYY-MM-DD');
        }
      },
      immediate: true
    },
    menu(val) {
      if (val) {
        if (!this.date) {
          this.dateForPicker = dayjs('1940/1/1 00:00:00').format('YYYY-MM-DD');
        }else{
          this.dateForPicker = dayjs(this.date).format('YYYY-MM-DD');
        }
        //年をカレンダーの初期表示にする・・・のだが、エラーが発生し、動作が不安定なためコメントアウト
        //val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
      }
    },
    date(val) {
      this.$emit("update:modelValue", val);
    }
  },
  unmounted() {
    if(this.modelValue && !this.preventEmitOnUnmount){
      this.$emit("update:modelValue", null);
    }
  },
  methods: {
    save(date) {
      this.date = date;
      this.menu = false
    },
    jpvYearFormat(str) {
      if(!str) return ''
      const year = this.type === 'date' ? str.split('-')[0]: str
      let wareki = this.year2Wareki(year);
      return `${year}年` + (wareki !== false ? `（${wareki}）` : '');
    },
    year2Wareki(year) {
      let wYear = ''
      let gen = ''
      if(year > 2018){
        wYear = year - 2018
        gen = '令和'
      }else if(year > 1988) {
        wYear= year - 1988
        gen = '平成'
      }else if(year > 1925) {
        wYear = year - 1925
        gen = '昭和'
      }else if(year > 1911) {
        wYear = year - 1911
        gen = '大正'
      }else if(year > 1867) {
        wYear = year - 1867
        gen = '明治'
      }
      if(wYear === 1) wYear = '元'
      return gen !== '' ? gen + wYear + '年' : false
    }    
  },
};
</script>
<style scoped lang="scss">
.init_date :deep(div.v-input__control input){
  color: #F6F6F6;
}
:deep(.v-date-picker-controls__mode-btn){
  width: 5rem;
  span i::after {
    content: "年選択";
    font-size:0.8rem;
    font-weight: bold;
  }
}
:deep(.v-date-picker--year .v-date-picker-controls .v-date-picker-controls__mode-btn ){
  transform: rotate(0deg) !important;
}
</style>
