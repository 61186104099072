<script>
import { computed } from 'vue'
import { Line as Liner } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: { Liner },
  props: {
    chartData: {
      type: Object,
        default: () => ({ labels: [], datasets: [] })  // デフォルト値の提供
     },
    customOptions: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const defaultOptions = {
      responsive: true,
      animation: false,
    }

    const chartOptions = computed(() => {
      return { ...defaultOptions, ...props.customOptions }
    });

    return { chartOptions }
  }
}
</script>

<template>
  <Liner
    :data="chartData"
    :options="chartOptions"
  />
</template>