import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.locale('ja');
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

import axios from 'axios';
import _ from 'lodash';

/**
 * htmlエスケープ
 * @param {*} string 
 * @returns エスケープ文字列
 */
function html_escape(string) {
  if (typeof string != 'string') {
    return string;
  }  
  return string.replace(/[&'`"<>]/g, function(match) {
    return {
      '&': '&amp;',
      "'": '&#x27;',
      '`': '&#x60;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;',
    }[match]
  });
}

/**
 * htmlアンエスケープ
 * @param {*} string 
 * @returns アンエスケープ文字列
 */
 function html_unescape(string) {
  if (typeof string != 'string') {
    return string;
  }  
  return string.replace(/&(lt|gt|amp|quot|#x27|#x60);/g, function(match) {
    return {
      '&amp;' : '&',
      '&#x27;': "'",
      '&#x60;': '`',
      '&quot;': '"',
      '&lt;'  : '<',
      '&gt;'  : '>',
    }[match]
  });
}

export default {
  async findInfos({ targetDtm }) {
    let res = await axios.get("/api/user/v1/information", {
      params: {
        target_dtm: targetDtm
      }
    });
    let data = res.data;
    _.forEach(data, item => {
      item.information_text = html_unescape(item.information_text);
    });
    return data;
  },


  async authenticate({ accountName, accountPassword, rememberMe }) {
    let res = await axios.post('/api/user/v1/authenticate', {
      accountName,
      accountPassword,
      rememberMe
    });
    return res.data.token;
  },
  async findSelf({ token }) {
    let res = await axios.get("/api/user/v1/self", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    let item = res.data;
    item.userName = html_unescape(item.userName);
    item.userMail = html_unescape(item.userMail);
    item.accountName = html_unescape(item.accountName);
    //alert(JSON.stringify(item));
    return item;
  },
  async updateUserInfo({ token, userName, userMail, aiModelDefault }) {
    // user更新
    await axios.put("/api/user/v1/self", {
      userName: html_escape(userName),
      userMail: html_escape(userMail),
      aiModelDefault
    }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });

    // user取得
    return await this.findSelf({ token });
  },
  async changePassword({ token, oldPassword, newPassword }) {
    // 旧パスワードのチェック
    await axios.put("/api/user/v1/account", {
      oldPassword: html_escape(oldPassword),
      newPassword: html_escape(newPassword),
    }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return true;
  },
  async findOffices({ token }) {
    let res = await axios.get("/api/user/v1/offices", {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    let data = res.data;
    _.forEach(data, item => {
      item.officeName = html_unescape(item.officeName);
      item.address = html_unescape(item.address);
    });
    return data;
  },

  async findFacilities({ token, fiscalYear }) {
    let res = await axios.get(`/api/user/v1/facilities/${fiscalYear}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    }).catch(err => {
      throw err;
    });
    let data = res.data;
    data.forEach(elem => {
      _.forEach(elem, item => {
        item.name = html_unescape(item.name);
      });
    })
    return data;
  },

  /**
   * ゲスト作成
   * @param {*} param0 
   * @returns 
   */
  async createGuest({ token, officeId, insuranceId, guestLastName, guestFirstName, guestAddress, sexTypeCd, guestBirthDt, managementNo }) {
    let res = await axios.post("/api/user/v1/guests", {
      officeId,
      insuranceId,
      guestLastName: html_escape(guestLastName),
      guestFirstName: html_escape(guestFirstName),
      guestAddress: html_escape(guestAddress),
      sexTypeCd,
      guestBirthDt,
      managementNo: html_escape(managementNo),
    }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async updateGuest({ token, guestId, officeId, insuranceId, guestLastName, guestFirstName, guestAddress, sexTypeCd, guestBirthDt, managementNo }) {
    let res = await axios.put(`/api/user/v1/guests/${guestId}`, {
      insuranceId,
      officeId,
      guestLastName: html_escape(guestLastName),
      guestFirstName: html_escape(guestFirstName),
      guestAddress: html_escape(guestAddress),
      sexTypeCd,
      guestBirthDt,
      managementNo: html_escape(managementNo),
    }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  async findGuests({ token }) {
    let res = await axios.get("/api/user/v1/guests", {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    let data = res.data;
    _.forEach(data, item => {
      item.lastName = html_unescape(item.lastName);
      item.firstName = html_unescape(item.firstName);
      item.managementNo = html_unescape(item.managementNo);
      item.insuranceId = html_unescape(item.insuranceId);
      let offices = item.offices;
      _.forEach(offices, office => {
        office.officeName = html_unescape(office.officeName)
      })
    });
    return data;
  },

  async findHists({ token }) {
    let res = await axios.get("/api/user/v1/hists", {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    let data = res.data;
    _.forEach(data, item => {
      item.lastName = html_unescape(item.lastName);
      item.firstName = html_unescape(item.firstName);
      item.managementNo = html_unescape(item.managementNo);
      item.insuranceId = html_unescape(item.insuranceId);
      let offices = item.offices;
      _.forEach(offices, office => {
        office.officeName = html_unescape(office.officeName)
      })
    });
    return data;
  },

  async deleteGuest({ token, guestId }) {
    let res = await axios.delete(`/api/user/v1/guests/${guestId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },

  async findSexTypes({ token }) {
    let res = await axios.get("/api/user/v1/const/sex-types", {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  
  async createCheckList({ token, guestId, targetDt, checklists, results }) {
    for (let key in checklists) {
      checklists[key] = html_escape(checklists[key]);
    }
    for (let key in results) {
      results[key] = html_escape(results[key]);
    }
    let res = await axios.post(`/api/user/v1/guests/${guestId}/check-lists`,
      {
        targetDt,
        checklists: JSON.stringify(checklists),
        results: JSON.stringify(results),
      },
      {headers: {'Authorization': `Bearer ${token}`}}
    );
    return res.data;
  },

  async findCheckLists({ token, guestId }) {
    let res = await axios.get(`/api/user/v1/guests/${guestId}/check-lists`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    let data = res.data;
    data.forEach(elem => {
      for (let key in elem.checklists) {
        elem.checklists[key] = html_unescape(elem.checklists[key]);
      }
      for (let key in elem.results) {
        elem.results[key] = html_unescape(elem.results[key]);
      }
    });
    return data;
  },

  async deleteCheckList({ token, checkListId }) {
    let res = await axios.delete(`/api/user/v1/check-lists/${checkListId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },

  /**
   * チェックリスト - チェックリスト保存
   * @param {*} param0 
   * @returns 
   */
  async saveCheckListChecklists({ token, checkListId, checklists }) {
    for (let key in checklists) {
      checklists[key] = html_escape(checklists[key]);
    }
    checklists = JSON.stringify(checklists);
    let res = await axios.put(`/api/user/v1/check-lists/${checkListId}/checklists`, { checklists }, {
      headers: {'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  
  async saveCheckListResults({ token, checkListId, results }) {
    for (let key in results) {
      results[key] = html_escape(results[key]);
    }
    results = JSON.stringify(results);
    let res = await axios.put(`/api/user/v1/check-lists/${checkListId}/results`, { results }, {
      headers: {'Authorization': `Bearer ${token}`}
    });
    return res.data;
  },

  async saveCheckList({ token, checkListId, checklists, results }) {
    for (let key in checklists) {
      checklists[key] = html_escape(checklists[key]);
    }
    checklists = JSON.stringify(checklists);
    results = JSON.stringify(results);
    let res = await axios.put(`/api/user/v1/check-lists/${checkListId}`, { checklists, results }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  /**
   * サーバのPDF作成API呼び出し
   * @param {} param0 
   */
  async pdfCheckList({ token, guests }) {
    guests = JSON.stringify(guests);
    let res = await axios.post(`/api/pdf/v1/check-lists/`, {
      guests
    }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  /**
   * 予測PDF呼び出し
   * @param {*} param0 
   * @returns 
   */
  async pdfPrediction({ token, guests }) {
    guests = JSON.stringify(guests);
    let res = await axios.post(`/api/pdf2/v1/prediction/`, {
      guests
    }, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },

  /**
   * フレイル予測AI呼び出し
   */
  async callPredictFrail({ token, query }) {
    let res = await axios.post('/api/user/v1/soin-ai/predict_frail', { query }, {
      headers: { 'Authorization': `Bearer ${token}` }
    }).catch(err => {
      throw err;
    });
    return res;
  },
  
  // ChatGPTからの戻りをストリーム表示
  async *streamChatCompletion({ token, query }) {
    const completion = await fetch(
      `/api/user/v1/soin-ai/stream_chat_completion`,
      {
        method: "POST",
        body: JSON.stringify(query),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      }
    );
    const reader = completion.body?.getReader();

    if (completion.status !== 200 || !reader) {
      throw new Error("Request failed");
    }

    const decoder = new TextDecoder("utf-8");
    let done = false;
    while (!done) {
      const { done: readDone, value } = await reader.read();
      if (readDone) {
        done = readDone;
        reader.releaseLock();
      } else {
        const token = decoder.decode(value, { stream: true });
        yield token;
      }
    }
  },

  // ChatGPTへの質問事項と将来の状態等を送信
  async callApiStreamGpt({ token, query }) {
    let res = await axios.post('/api/user/v1/soin-ai/chat_prompt', { query }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).catch(err => {
      throw err;
    });
    return res;
  },

  /**
   * ログ保存
   */
  async createLog({ token, message, description }) {
    let res = await axios.post('/api/user/v1/log', { message, description }, {
      headers: { 'Authorization': `Bearer ${token}` }
    }).catch(err => {
      throw err;
    });
    return res;
  },
  
  /**
   *  環境変数取得
   */
  async env({ token, name }) {
    let res = await axios.get(`/api/user/v1/env/${name}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
  
  /**
   * ping/pong
   */
  async ping({ token }) {
    let res = await axios.post('/api/user/v1/ping', {}, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return res.data;
  },
};